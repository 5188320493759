.manage-news {
  padding-top: 4rem;
  
  .news-list {
    .news-list-header {
      display: flex;
      width: 75%;
      align-items: center;
      margin-bottom: 30px;

      .filters {
        margin-left: 40px;
        display: flex;
        p {
          margin: 0 10px 0 0;
          cursor: pointer;
        }
      }
    }
    .news-list-content {
      .table-wrapper {
        width: 50%;
        margin-top: 10px;
        max-height: 500px;
        overflow-y: scroll;

        .pointer {
          cursor: pointer;
        }

        table {
          width: 100%;
          thead {
            text-align: left;
          }
          tbody {
            tr {
              td {
                img {
                  width: 15px;
                  margin-left: 5px;
                }
                a {
                  color: $application-color;
                }
              }
            }
          }
        }
      }
      button {
        cursor: pointer;
        margin-top: 30px;
        background-color: $application-color;
        width: 150px;
        height: 30px;
        border: none;
        color: $white;
        border-radius: 4px;
      }
    }
  }
}

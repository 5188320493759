.home-news {
    border-bottom: 1px solid $very-light-pink-two;

    @media (max-width: 960px) {
        padding: 15px 50px;
    }

    .title {
        text-align: center;
        width: 100%;
        margin: 30px 0;
    }

    .news-items {
        @media (min-width: 960px) {
            display: flex;
            justify-content: space-evenly;
        }
    }

    .more-news {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $Montserrat;
        font-weight: $regular;
        color: $brownish-grey;
        text-decoration: none;
        padding: 30px;

        &:hover {
            color: #949494;
        }

        svg {
            height: 10px;
            width: auto;
            margin-left: 8px;
            margin-top: 2px;
        }
    }

    .news-card-container {
        @media (min-width: 960px) {
            width: 30%;
        }

        &:hover {
            cursor: pointer;
        }

        .news-card {
            box-shadow: rgb(124, 124, 124) 0px 0px 10px;
            padding: 5px;
            margin-bottom: 15px;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }


            .image {
                width: 100%;
                height: 150px;
                overflow: hidden;
                background-size: cover;
            }

            p {
                padding: 10px;
            }

            .item-title {
                font-family: $WorkSans;
                font-size: 17px;
                color: $black;
                box-sizing: border-box;
                margin-bottom: 0px;
            }

            .date {
                font-family: $Montserrat;
                font-weight: $regular;
                color: $brownish-grey;
                font-size: 10px;
                text-transform: uppercase;
                margin-bottom: 0px;
            }
        }
    }
}

.container {
  max-width: $breakpoint-desktop;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-xl {
  max-width: $breakpoint-desktop-xl;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}
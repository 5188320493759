.store-admin {
  padding: 1em;
  @include desktop {
    padding: 0;
  }
  h1 {
    font-size: 32px;
    color: $black;
    font-family: $WorkSans;
    font-weight: 900;
  }
  p {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 300;
    color: $brownish-grey;
  }

  h2 {
    font-family: $WorkSans;
    font-size: 2rem;
    font-weight: 900;
    color: $black;
    margin-bottom: 40px;
  }
}


@import "store-clothing-categories";
@import "store-images";
@import "create-coupon";

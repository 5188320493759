@import "./filter-menu-search";
@import "./filter-menu-category-items";

.filter-menu-category {
    border-top: 1px solid $very-light-pink-two;
    padding: 14px 0;

    @include desktop {
        width: 250px;
        border-top: 2px dashed darken($very-light-pink-two, 5);

        &:nth-child(2) {
            border: none;
        }
    }

    &__title {
        padding: 3px 20px;
        margin: 0 0 0px 0;
        display: inline-block;
        font-family: $Montserrat;
        font-size: 1rem;
        color: $black;

        @media (min-width: 960px) {
            font-weight: $bold;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;

        @media (min-width: 960px) {
            padding-right: 20px;
        }
    }

    &__restore-button {
        background-color: $application-color-transparent;
        font-family: $Montserrat;
        font-weight: $regular;
        font-size: 14px;
        text-transform: lowercase;
        border: none;
        padding: 5px;
        border-radius: 2px;

        @media (max-width: 959px) {
            margin-right: 10px;
        }

        svg {
            height: 10px;
            width: auto;

            path {
                fill: $brownish-grey;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__arrow {
        width: 20px;
        height: auto;
        float: right;
        margin-right: 25px;
        transform: rotate(180deg);
    }

    &--close {
        .filter-menu-category-items {
            height: 0px;
            overflow: hidden;
        }

        .filter-menu-category__title {
            margin: 0;
        }

        .filter-menu-category__arrow {
            transform: rotate(0deg);
        }

        .filter-menu-category__top {
            margin-bottom: 0px;
        }

        .filter-menu-search {
            display: none;
        }
    }
}
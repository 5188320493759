.manage-stores {
    padding-top: 4rem;
    
    .button {
        margin-bottom: 0;

        &-dummy {
            margin-left: 10px;
        }
    }
    
    .ml-1 {
        margin-left: 10px;
    }

    .popup {
        .select {
            width: 100%;
        }

        input {
            box-sizing: border-box;
        }
    }

    .buttons-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}

.my-reviews--store {
  .reviews-header {
    border: none;
  }

  h5 {
    color: $application-color;
  }

  .reviews-body {
    .review-shop {
      display: flex;
      flex-direction: row;

      .review-image {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        @include desktop {
          width: 120px;
        }

        img {
          width: 60px;
        }

        h5 {
          text-align: center;
          margin-top: 14px;
          margin-bottom: 5px;
          font-size: 16px;
        }

        p.subtitle {
          text-align: center;
          font-size: 12px;
          color: $brown-grey;
        }
      }

      .shop-body {
        margin-top: 51px;
        width: 100%;

        .review {
          .review-header {
            .review-header-row {
              .my-review-title {
                h5 {
                  color: $application-color !important;
                }
              }
            }
          }

          .review-response {
            margin-left: 0;

            .response-image {
              display: flex;
              flex-direction: column;
              align-items: center;

              h5 {
                text-align: center;
              }
            }

            .response {
              width: 100%;
              margin-left: 49px;

              .response-title {
                display: flex;

                img {
                  margin-left: auto;
                  width: 20px;
                }
              }

              .response-body {
                margin-top: 0;
              }
            }
          }

          .review-response-area {
            .review-response {
              .response {
                margin-left: 49px;

                .response-body {
                  margin-top: 0;
                  width: auto;

                  textarea {
                    border: 1px solid $brownish-grey;
                    border-radius: 4px;
                    padding: 22px 19px;
                    color: $brownish-grey;
                    font-family: $Montserrat;
                    font-weight: $light;
                  }
                }

                button.submit {
                  width: 35%;
                  display: flex;
                  color: $white;
                  background-color: $application-color;
                  border-radius: 4px;
                  padding-left: 15px;
                  font-family: $Montserrat;
                  font-weight: $medium;

                  img {
                    margin-left: auto;
                  }
                }
              }
            }

            .review-response.hidden {
              display: none;
            }

            button {
              margin-top: 43px;
              border: none;
              background-color: transparent;
              display: flex;
              align-items: center;
              width: 30vw;
              cursor: pointer;
              font-family: $Montserrat;
              font-weight: $bold;
              text-transform: uppercase;
              font-size: 0.8rem;

              img {
                width: 25px;
                margin-right: 2.5%;
              }
            }

            button.hidden {
              display: none;
            }
          }
        }
      }
    }
  }
}
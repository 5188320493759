.inner-logo-address {
  padding: 0 15px;
  margin: 22px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  font-family: $Montserrat;
  font-size: 14px;


  .logo, .navigator {
    flex-grow: 1;
    flex-basis: 40%;
  }

  .logo {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .logo, .navigator, .address-contact {
    @include tablet {
      padding: 0 1em;
      //height: 141px;
      //width: 141px;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }



  .divider {
    margin: 0 13px;
    width: 0;
    border-right: solid 1px $very-light-pink-two;

    &.hide-mobile {
      display: none;
      @include tablet {
        display: block !important;
      }
    }

  }

  .address-contact {



    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    @include tablet {
      align-items: center;
    }

    &.hide-mobile {
      display: none;
      @include tablet {
        display: flex;
      }
    }

    &.hide-tablet {
      display: flex;
      margin: 24px 0 6px;
      @include tablet{
        display: none;
      }
    }

    .address {
      color: $brown-grey;
    }

    .contact {
      display: flex;
      justify-content: space-between;

      @include tablet {
        flex-direction: column;
      }
    }

    a {
      color: $application-color;
      font-weight: bold;
      text-decoration: none;
    }


  }


}

.store-info {


  .tabs {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 30px 14px 8px;
    border-bottom: 1px $very-light-pink-two solid;

    @include desktop {
      padding-left: 0;
      padding-right: 0;
      overflow-x: auto;
      border-bottom: 0;
      display: flex;
    }

    .tab {
      cursor: pointer;

      font-size: 16px;
      color: $brownish-grey;
      font-family: $WorkSans;
      margin-right: 1em;

      &.active {
        text-decoration: underline;
      }

      @include desktop {
        margin: 0;
        flex-grow: 1;
        text-align: center;
        border: solid 1px $brownish-grey;
        border-right: 0;
        padding: 20px;

        &.active {
          color: $text-color-primary;
          font-weight: 500;
          background: $background-color-primary;
          text-decoration: none;
        }

        &.reviews {
          border-right: 1px solid;
        }
      }
    }
  }

  .store-info-content {
    padding: 1em 0;

    @include tablet {
      padding: 16px 0 61px
    }

    .detail-section {

      padding: 0 14px;

      @include tablet {
        padding: 0;

      }

      display: none;

      &.active {
        display: block;
      }

      color: $brown-grey;

      .detail-section-component {
        p, a, i, b, strong, em, span, blockquote {
          font-family: $Montserrat !important;
          color: $black !important;
          font-size: 1rem !important;
        }

        @include tablet {
          padding: 1em 37px ;

          border-bottom: 1px $very-light-pink-two solid;
        }
      }

      h2 {
        font-size: 2rem;
        color: $black;
        font-family: $Montserrat;
        line-height: 1.5;

        @include tablet {
          font-size: 23px;
        }
      }


      h3 {
        font-size: 14px;
        color: $black;
        font-family: $Montserrat;

        @include tablet{
          font-size: 18px;
        }
      }

    }

    .hours-container {
      span {
        font-family: $Montserrat;
      }

      @include tablet{
        display: flex;
      }

      .standard, .custom {
        align-items: flex-start;

        @include tablet {
          display: inline-block;
          width: auto;


          margin-right: 4rem;
        }
      }

      ul {
        list-style: none;
        margin: 0 0 1em;
        padding: 0;
      }

      .hours {
        font-family: $Montserrat;
        margin-bottom: .3rem;


        &__line {
          display: flex;
          justify-content: flex-start;
          line-height: 1.4;

          &--closed{
            font-style: italic;
            font-size: 0.8em;
            font-weight: 300;
            padding-left: .5rem;
            line-height: 1em;
          }
        }

        &__day {
          min-width: 180px;
          @include tablet {
            min-width: 250px;
          }

          width: 20%;

          &--closed {

          }
        }

        &__times {
          display: flex;
          justify-content: flex-start;
          width: 150px;

          :first-child {
            width: 35%;
            @include tablet {
              width: 30%;
            }
          }

          :last-child {
            margin-left: .6rem;
          }

          &--closed {
            :first-child {
              width: 30%;
              @include tablet {
                width: 25%;
              }
            }
          }
        }
      }
    }
  }

  .show-on-desktop {
    display: none;
    font-family: $WorkSans;

    @include desktop {
      display: inline-block;
    }
  }

  @include desktop {
    .hide-on-desktop {
      display: none;
    }
  }

  .extra-info-component {
    h4 {
      font-family: $Montserrat;
      color: $black;
    }
    margin-bottom: 1em;
  }




  .payment-methods {
    > div {
      display: inline-block;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .social-media-accounts {
    > div {
      display: inline-block;
      margin-right: 1em;

      img {
        max-height: 45px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .travel-options {
    > div {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .img-container {
        max-width: 30px;
        max-height: 30px;
        margin-right: 20px;

        img {
          max-height: 30px;
        }
      }

      span {

      }
    }

  }

  .what-we-sell {
    h2 {
      margin-bottom: 4px;
    }
    .clothing-categories {
      margin-bottom: 1em;
      margin-left: 2em;

      .clothing-category {
        display: inline-block;
        border: 1px solid $brown-grey;
        border-radius: 4px;
        padding: 3px 6px;
        margin-right: 9px;
        margin-bottom: 9px;
      }
    }
  }

  .extra-info-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h4 {
      margin-top: 0;
    }

    & > div > div {
      margin-bottom: 2rem;
    }

    @include tablet {
      flex-direction: row-reverse;
    }

    .google-map {
      iframe {
        width: 100%;
        height: 80vw;

        @include tablet {
          width: 443px;
          height: 341px;
        }

      }
    }
  }
}

footer {
    background-color: $black;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 7px;
    max-width: 960px;
    margin: auto;

    h3 {
        margin: 0px;
        margin-bottom: 10px;
        color: $white;
        font-family: $Montserrat;
        font-weight: $medium;
    }

    a {
        font-family: $Montserrat;
        text-decoration: none;
        font-weight: medium;
        color: $white;
        display: block;
        padding: 10px 0 10px 0;
    }

    #footer-section {
        div {
            width: 50%;
            box-sizing: border-box;
            padding: 20px;
            float: left;
        }
    }

    #footer-social {
        background-color: $grey;
        padding: 15px;

        div {
            display: flex;
            justify-content: center;
            margin-bottom: 7px;
        }
        
        img {
            width: 50px;
            height: auto;
            margin: 0 5px 0 5px;

            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(4346%) hue-rotate(54deg) brightness(117%) contrast(111%);
        }

        path {
            fill: #fff;
        }
    }

    .copyright {
        color: $white;
        padding: 0;
        margin: 2px;
        text-align: center;
        font-size: 11px;
        font-family: $Montserrat;
        padding: 10px;

        @media (min-width: 960px) {
            color: $black;
            display: inline;
            padding: 40px;
            font-size: 14px;
            border-top: 1px solid $very-light-pink-three;
        }
    }

    .follow {
        font-size: 16px;
        font-family: $WorkSans;
        font-weight: $medium;
        color: $brownish-grey;
        display: block;
        width: 100%;
        text-align: center;
    }

    @media (min-width: 960px) {
        background-color: $white;
        color: $black;
        display: flex;
        justify-content: center;

        a:hover {
            color: #949494;
        }

        #footer-section {
            div {
                width: 33.33%;
                box-sizing: border-box;
                padding: 40px;
                float: left;
                border-top: 1px solid $very-light-pink-three;
                margin-top: 50px;

                h3 {
                    font-size: 16px;
                    font-weight: $semibold;
                }
            }
        }

        #footer-social {
            background-color: $white;
            border-top: 1px solid $very-light-pink-three;
            padding: 40px;

            img {
                filter: none;
            }

            svg {
                path {
                    fill: $application-color;
                }
            }
        }

        h1, h2, h3, h4, p, a {
            color: $black;
        }
    }
}

.create-coupon {
    @media (max-width: 960px) {
        padding: 1rem;
    }

    @include desktop {
        padding-top: 3rem;
    }

    h1,
    h2 {
        color: $black;
        font-family: $WorkSans;
        text-transform: uppercase;
    }

    h1 {
        font-size: 2rem;
        font-weight: $heavy;
    }

    h2 {
        font-size: 1.2rem;
        font-weight: $bold;
    }

    input {
        padding: 10px 10px;
        font-family: $WorkSans;
        box-sizing: border-box;
        width: 100%;
    }

    &__subtitle {
        font-weight: $bold;
    }

    .image-select {
        width: 371px;
        height: 200px;

        button {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            width: 371px;
            height: 200px;
            background-color: $very-light-pink-two;
            border-radius: 0;
        }
    }

    &__form,
    &__preview {
        display: inline-flex;
        box-sizing: border-box;
        width: 50%;
        flex-direction: column;
    }

    &__form {
        padding-right: 1rem;
    }

    &__preview {
        padding-left: 1rem;
    }

    &__button {
        display: block;
        margin-top: 15px;
    }

    &__preview-wrapper {
        display: inline-flex;
        box-sizing: border-box;
        width: 100%;
        justify-content: center;

    }
}

.store-details {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4rem;

    @media (max-width: 960px) {
        padding: 2rem 1rem 1rem 1rem;
    }

    * {
        box-sizing: border-box;
        font-family: $Montserrat;
    }

    .store-details-header {
        h1 {
            text-transform: uppercase;
            font-size: 32px;
            color: $black;
            font-weight: $heavy;
        }

        .subtitle {
            color: $brownish-grey;
            font-family: $Montserrat;
            font-weight: $light;
            font-size: 16px;
        }
    }

    .store-details-body {
        input {
            width: 100%;
            border-radius: 4px;
            border: 1px solid $brownish-grey;
            line-height: 3rem;
            padding-left: 1rem;
            font-family: $Montserrat;
            font-weight: $light;
        }

        .css-2b097c-container {
            width: 105%;

            .css-yk16xz-control {
                border-color: $brownish-grey;
            }
        }

        input::placeholder {
            color: $brown-grey;
        }

        .section-header {
            h3 {
                font-family: $WorkSans;
                font-weight: $heavy;
                color: $black;
                text-transform: uppercase;
                font-size: 26px;

                .section-icon {
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                    background-color: $application-color;

                    p {
                        position: absolute;
                        color: $white;
                        left: 5px;
                        font-size: 5px;
                        top: 0px;
                    }
                }
            }
        }

        .errors {
            color: red;
        }

        .section-body {
            display: flex;
            flex-direction: column;

            .section-container {
                overflow-x: auto;
                display: flex;
                flex-direction: column;
                max-width: 100%;
            }

            .section-row {
                margin-bottom: 1rem;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .section-field {
                    width: 45%;

                    h4,
                    label {
                        margin-top: 0;
                        margin-bottom: 2.5vh;
                        display: block;
                        font-family: $WorkSans;
                        font-weight: bold;
                        font-size: 20px;
                        text-transform: uppercase;
                        color: $black;
                    }
                }
            }

            .submit-changes {
                padding: 0px 1rem;
                cursor: pointer;
                margin: 30px 0 50px 0;
                align-self: flex-start;
                font-size: 18px;
                font-family: $Montserrat;
                font-weight: $light;
                background-color: $application-color;
                border: none;
                border-radius: 4px;
                line-height: 3rem;
                color: $white;
                font-family: $Montserrat;
                font-weight: $medium;
            }

            .section-field:last-child {
                align-self: flex-end;
            }

            .section-field {
                .house-number-zip-code {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    .house-number {
                        width: 35%;
                    }

                    .zip-code {
                        margin-left: auto;
                        width: 50%;
                    }
                }
            }

            .section-field.shop-logo {
                width: 100%;

                .image-select {
                    width: 150px;
                    height: 150px;
                    display: flex;
                    background-color: $very-light-pink-two;
                    justify-content: center;

                    button {
                        cursor: pointer;
                        border: none;
                        background: none;
                        background-size: cover;
                        width: 100%;
                    }

                    span {
                        align-self: center;
                        margin-bottom: 0;
                        text-decoration: underline;
                    }
                }
            }

            .store-details-checkbox {
                width: 7.5%;

                .checkbox {
                    cursor: pointer;
                    top: -12px;
                    position: relative;
                    padding-left: 35px;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        width: 0;
                        height: 0;
                    }

                    .checkmark {
                        position: absolute;
                        left: 0;
                        width: 23px;
                        height: 23px;
                        border-radius: 2px;
                        background-color: $white;
                        border: 1px solid $brown-grey;
                    }

                    input:checked ~ .checkmark {
                        background-color: $application-color;
                        border: none;
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked ~ .checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        left: 8px;
                        top: 3px;
                        width: 5px;
                        height: 11px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            .social-media-table {
                width: 100%;

                .social-media-row > td {
                    padding-bottom: 2.5%;
                }
            }

            .opening-times-table {
                border-collapse: separate;
                min-width: 1000px;

                thead {
                    tr {
                        width: 100%;
                        display: flex;

                        th {
                            width: 92px;
                            margin-right: 30px;
                            display: inline-block;
                            align-self: center;
                            text-overflow: clip;

                            white-space: nowrap;
                            font-weight: bold;
                            text-transform: uppercase;
                            color: $black;
                            font-size: 20px;

                            &:first-child {
                                width: 150px;
                            }

                            &.spacer {
                                width: 5%;
                            }
                        }
                    }


                }

                tbody {
                    tr {
                        width: 100%;
                        display: flex;
                        padding-bottom: 20px;

                        td {
                            width: 92px;
                            margin-right: 30px;
                            display: inline-block;
                            align-self: center;

                            &:first-child {
                                width: 150px;
                            }
                        }

                        td.spacer {
                            width: 5%;
                        }
                    }
                }
            }

            .opening-times-table.special-times {
                tbody {
                    tr {
                        td.wider {
                            text-overflow: clip;
                            white-space: nowrap;
                        }

                        td.special-times {
                            font-family: $Montserrat;
                            font-weight: $light;
                            font-size: 16px;
                        }

                        td.wider.add-date {
                            p {
                                font-family: $Montserrat;
                                font-weight: bold;
                                text-transform: uppercase;
                                color: black;
                                font-size: 14px;
                                display: inline-block;
                                height: 100%;
                                margin-bottom: 0;
                                vertical-align: middle;
                                cursor: pointer;
                            }

                            button.add-special-time {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                background-color: $application-color;
                                border-radius: 50%;
                                border: none;
                                position: relative;
                                color: $white;
                                vertical-align: middle;
                                cursor: pointer;
                            }

                            button.add-special-time::before,
                            button.add-special-time::after {
                                content: " ";
                                position: absolute;
                                display: block;
                                background-color: $white;
                            }

                            button.add-special-time::before {
                                height: 2px;
                                margin-top: -1px;
                                top: 50%;
                                left: 5px;
                                right: 5px;
                            }

                            button.add-special-time::after {
                                width: 2px;
                                margin-left: -1px;
                                left: 50%;
                                top: 5px;
                                bottom: 5px;
                            }
                        }

                        td.remove-special-date {
                            color: $application-color;
                            font-size: 1rem;
                            font-weight: $light;
                            position: relative;
                            left: 100px;
                            cursor: pointer;
                        }

                        td {
                            input.special-time-description {
                                width: 305px;
                            }
                        }
                    }
                }
            }

            .section-field.payment-methods,
            .section-field.travel-options,
            .section-field.extra-services {
                display: flex;
                width: 60%;
                flex-wrap: wrap;
                justify-content: space-between;

                .checkbox-item {
                    display: flex;
                    width: 40%;
                    margin-bottom: 20px;
                    align-items: center;

                    img {
                        vertical-align: middle;
                        margin-right: 15px;
                        max-height: 30px;
                    }

                    p {
                        vertical-align: middle;
                        margin: 0;
                    }

                    .store-details-checkbox {
                        margin-left: auto;
                    }
                }
            }

            .section-field.about-store {
                width: 100%;

                textarea {
                    border-radius: 4px;
                    width: 100%;
                    border: 1px solid $brownish-grey;
                    padding: 20px;
                }
            }

            .section-field.short-about-store {
                width: 100%;

                input {
                    border-radius: 4px;
                    width: 100%;
                    border: 1px solid $brownish-grey;
                }
            }

            .store-details-icon {
                width: 7.5%;

                img {
                    width: 60%;
                }
            }

            .social-media-input {
                width: auto;

                input.hidden {
                    display: inline-block;
                    opacity: 0;
                }
            }
        }
    }
}

.store-reviews-container {
  .overall-ratings {
    display: flex;
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .store-rating {
        font-family: $WorkSans;
        color: $brownish-grey;
        font-size: 20px;
        margin-bottom: 1rem;

        @include tablet {
          font-size: 30px;
        }

        span {
          font-size: 45px;
          font-weight: 600;

          @include tablet {
            font-size: 60px;
          }
        }

      }

      p {
        font-family: $Montserrat;
        font-weight: normal;
        font-size: 12px;
        color: $brown-grey;

        @include tablet {
          font-size: 14px;
        }
      }
    }
  }

  .store-review {
    padding: 35px 0;
    border-bottom: 2px solid $very-light-pink-two;


    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;
    }

    .review-user {
      display: flex;
      align-items: center;
      margin-top: 23px;
      @include tablet {
        flex-direction: column;
        order: 1;
        margin-right: 37px;
        margin-top: 0;

          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 100px;

        div {
          text-align: center;
        }
      }
    }

    .user-avatar svg, img {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      @include tablet {
        margin-right: 0;
        width: 59px;
        height: 59px;
        margin-bottom: 6px;
      }
    }

    .user-name {
      font-family: $Montserrat;
      font-weight: 600;
      font-size: 16px;

      p {
        margin: 0;
      }
    }

    .user-number-of-reviews {
      font-family: $Montserrat;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
    }

    .review-review {
      flex: 1;
      @include tablet {
        order: 2
      }

    }

    .review-header {
      display: flex;
      justify-content: space-between;
    }

    .review-title {
      font-family: $Montserrat;
      font-size: 16px;
      color: $application-color;
      font-weight: 600;
    }

    .review-content {
      margin-top: 10px;
      font-family: $Montserrat;
      font-size: 16px;
      color: $brownish-grey;
      font-weight: 400;
      font-style: italic;
    }

    .review-date {
      font-family: $Montserrat;
      line-height: 22px;
      font-size: 13px;
      font-weight: 300;
      color: $brown-grey;
    }

    .review-rating {
      font-family: $WorkSans;
      color: $brownish-grey;
      font-size: 12px;
      font-weight: normal;

      @include tablet {
        font-size: 16px;
      }

      span {
        font-size: 28px;

        font-weight: 500;

        @include tablet {
          font-size: 38.5px;
        }
      }
    }

    .review-store-reply {
      border-left: solid 1px $very-light-pink-two;

      margin-left: 30px;
      margin-top: 30px;
      padding-left: 18px;

      display: flex;
      flex-direction: column;

      @include tablet {
        flex-direction: row;
        margin-top: 39px;
        margin-left: 0;
        padding-left: 28px;
      }


      .review-user {
        order: 1;
        padding: 0;
        margin: 0;
      }

      .review-review {
        order: 2;

        @include tablet {
          padding-left: 2em;
        }
      }

      .review-content {
        margin: 0;
      }
    }

  }

  .store-reviews-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;

    @include tablet {
      padding: 2em 0 2em;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    button {
      margin-top: 1em;
      display: flex;
      align-items: center;

      @include tablet {
        margin-top: 0;
      }

      svg {
        width: 22px;
        height: 16px;
        margin-left: 20px;

        path {
          fill: $white;
        }
      }
    }
  }
}

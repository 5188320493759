.register-div {
    padding: 15px;

    ul, p {
        font-family: $Montserrat;
        font-size: 16px;
        font-weight: 300;
        color: $brownish-grey;
    }

    p {
        margin: 0;
    }

    ul {
        margin-top: 0;
        list-style: none;
        padding: 10px;

        li {
            display: flex;
            padding: 0.5em 0;


            div {
                margin-left: 1em;
            }

            svg {
                flex-shrink: 0;
                width: 15px;
                height: 36px;
            }
        }
    }
}

nav {
  color: white;
  position: relative;
  width: 100%;
  z-index: 1000;
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;

  .login-title {
    margin: 0 0 0 15px;
    padding: 0 25px 0 0;
    border-right: 1px solid currentColor;
    font-family: $WorkSans;
    font-weight: $medium;
    color: $text-color-primary;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: none;
  }

  #first-visit-banner {
    width: 100%;
    color: $brown-grey;
    font-size: 12px;
    display: flex;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    position: relative;

    .close {
      width: 1rem;
      height: auto;
      position: absolute;
      right: 1rem;
      top: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    #left {
      width: 50%;
      text-align: right;
      font-family: $Montserrat;
      padding: 10px;
      box-sizing: border-box;
      border-right: 1px solid $very-light-pink-two;
      display: flex;
      justify-content:center;
      flex-direction: column;
      align-items: flex-end;

      h2 {
        font-weight: $bold;
        font-size: 15px;
        color: $brown-grey;
      }

      p {
        font-weight: $light;
        font-size: 12px;
        margin: 0;
        max-width: 300px;
      }
    }

    #right {
      width: 50%;
      text-align: left;
      padding: 10px;
      display: flex;
      align-items: center;
      color: black;
      font-family: $Montserrat;
      font-size: 16px;

      svg {
        width: 4em;
        height: auto;
      }

      div {
        padding: 5px;

        p {
          font-size: 25px;
        }
      }

      #logo-fashion {
        font-weight: $bold;
      }

      p {
        margin: 0;
        font-style: normal;
      }
    }
  }

  .nav-cont {
    position: fixed;
    width: 100%;
    background: $background-color-primary;

    .nav-content {
      margin: 0 auto;
      padding: 16px 0px 16px 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      overflow: visible;
      max-width: 960px;

      @media (max-width: 960px) {
        padding: 16px;
      }

      .nav-main-menu {
        display: flex;
        align-items: center;
      }

      #logo {
        height: 43px;
        width: auto;
        margin-left: 0px;
      }

      #account {
        height: 43px;

        @media (min-width: 960px) {
          width: auto;
        }
      }

      #hamburger {
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        height: 32px;
        width: 32px;

        span {
          background-color: $white;
          height: 3px;
          width: 100%;
          border-radius: 2px;
        }
      }
    }
  }

  .nav-menu {
    position: fixed;
    right: -100%;
    width: 80%;
    background-color: $white;
    top: 0;
    transition: right 0.5s ease;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1001;
    max-height: 100%;

    &.open {
      right: 0%;
    }

    #close {
      height: 20px;
      width: 20px;
      margin-right: 11.5px;
      float: right;
    }

    .sub-menu-title {
      font-family: $WorkSans;
      font-weight: $book;
      color: $application-color;
      padding: 15px 20px;
      margin: 0;
    }

    .nav-menu-top {
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;

      p {
        width: 100%;
        margin: 0px;
        font-size: 16px;
        text-align: left;
        color: $application-color;
        font-family: $WorkSans;
        font-weight: $book;
      }
    }

    a {
      padding: 15px 0px;
      font-family: $WorkSans;
      font-weight: $book;
      color: $black;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid $light-grey;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }

}
@import "home-page-components/about";
@import "home-page-components/news";

#home {
    height: 100%;
    box-sizing: content-box;
    position: relative;
    max-width: 960px;
    margin: auto;

    * {
        box-sizing: border-box;
    }

    #intro-banner {
        @include width-full;
        position: relative;
    }

    #intro-banner-cont {
        position: relative;
        display: flex;
        justify-content: space-around;
        box-sizing: border-box;
        margin: 0 auto 25px;

        @media (min-width: 960px) {
            margin-bottom: 50px;
        }

        .intro-banner {
            width: 125%;
            margin-left: -25%;
        }

        .home-header {
            max-width: 100%;
            max-height: 40vh;

            @media (min-width: $breakpoint-desktop) {
                max-height: 60vh;
            }
        }

        .page-title {
            position: absolute;
            font-size: 24px;
            text-decoration: none;
            color: $application-color;
            font-family: $WorkSans;
            font-weight: $book;
            bottom: 20px;
            right: 20px;
            color: white;

            svg {
                path {
                    fill: white;
                }
                
                width: 20px;
                height: auto;
            }
        }
    }

    .user-banner {
        width: 100%;
    }

    .banner {
        width: 100%;
        height: 125px;
        background-size: cover;
        background-position: center;
        // background-image: linear-gradient(to left, $teal-blue, $cobalt-blue-66);
    }

    .title {
        font-family: $WorkSans;
        font-weight: $medium;
        color: $text-color-primary;
        font-size: 24px;
    }

    #home-city-pictures {
        margin-top: 50px;
        margin-bottom: 50px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        #city-pictures {
            @media (max-width: 959px) {
                overflow-x: scroll;
                display: flex;
                flex-direction: row;

                .city-container {
                    &:first-child {
                        margin-left: 15px;
                    }

                    .city {
                        width: 150px;
                        background-position: center;
                        background-size: cover;

                        p {
                            color: $white;
                            padding: 10px;
                            margin: 0;
                            font-family: $WorkSans;
                            font-weight: $book;
                            z-index: 2;
                        }
                    }
                }
            }
        }

        .show-all-cities {
            text-align: center;
            width: 100%;
            text-decoration: none;
            font-size: 15px;
            color: $brownish-grey;
            font-family: $Montserrat;
            font-weight: $regular;

            @media (max-width: 960px) {
                padding-top: 20px;
            }

            svg {
                height: 8px;
                width: auto;
                margin-left: 5px;
            }
        }
    }

    #participating-cities {
        @include width-full;

        padding: 25px 0;
        background-color: $background-color-secondary;

        .title {
            text-align: center;
            margin: 30px;
            font-family: $WorkSans;
            color: $text-color-primary;
        }

        p,
        a {
            font-family: $Montserrat;
        }

        .city-lists {
            background-position: center;
            background-size: cover;
            display: flex;
            flex-direction: row;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                width: 50%;
                display: inline-block;

                li {
                    margin-bottom: 15px;
                }

                p {
                    font-weight: $light;
                    color: $black;
                    font-size: 13px;
                    text-decoration: none;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    #new-hotspots {
        @include width-full;

        background-color: $background-color-primary;
        padding: 25px 0;

        .title {
            margin: 25px 15px;
            text-align: center;
            color: $text-color-primary;
        }

        .hotspots {
            display: flex;
            flex-direction: row;
            padding: 15px 0px;
            box-sizing: border-box;
            margin-bottom: 35px;

            @media (min-width: 500px) {
                justify-content: space-evenly;
            }

            @media (max-width: 499px) {
                overflow-x: scroll;
            }

            .store-card-container {
                &:hover {
                    cursor: pointer;
                }

                @media (min-width: 500px) {
                    width: 30%;
                }

                .store-card {
                    background-color: $white;
                    box-shadow: rgb(124, 124, 124) 0px 0px 10px;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    @media (max-width: 499px) {
                        width: 150px;
                        margin-right: 15px;

                        &:first-child {
                            margin-left: 15px;
                        }
                    }

                    .image {
                        width: 100%;
                        padding-top: 100%;
                        overflow: hidden;
                        background-size: cover;
                        background-position: center;
                    }

                    .text {
                        margin: 5px 10px;
                        box-sizing: border-box;
                        max-width: 100%;
                        padding-bottom: 10px;

                        .name {
                            font-family: $WorkSans;
                            font-weight: $regular;
                            color: $application-color;
                            font-size: 16px;
                            margin-bottom: 0px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .city {
                            font-family: $WorkSans;
                            font-weight: $book;
                            font-style: italic;
                            color: $brown-grey;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.pagination {
    list-style-type: none;
    padding: 15px;
    margin: 0px;
    display: flex;

    @media (max-width: 960px) {
        justify-content: center;
    }

    li {
        border: $very-light-pink-two 1px solid;
        margin-right: 10px;
        user-select: none;

        a {
            min-width: 30px;
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $Montserrat;
            font-weight: $regular;
            font-size: 16px;
            padding-top: 3px;

            &:focus {
                outline: none;
            }

            &:hover {
                cursor: pointer;
            }
        }

        &.active {
            background-color: $application-color;
            border: none;

            a {
                color: white;
            }
        }

        &.disabled {
            display: none;
        }

        @media (min-width: 960px) {
            &.previous {
                padding: 0 10px;
            }

            &.next {
                padding: 0 10px;
            }
        }
    }
}
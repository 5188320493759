.coupons {
    padding: 25px 15px;

    .coupons-body {
        .coupons-list {
            display: flex;
            flex-direction: column;
            align-items: center;

            .coupon-item {
                cursor: pointer;
                margin-bottom: 35px;

                .coupon-image {
                    width: 100%;
                }

                .coupon-text {
                    cursor: default;
                    margin-top: 15px;

                    h2,
                    p {
                        margin: 0;
                    }
                }
            }

            .coupon-item.disabled {
                cursor: default;
            }
        }
    }
}

.coupon-modal,
.coupon-modal-overlay {
    z-index: 2000;
    position: fixed;
    top: 0;
    right: 0;
    background-color: $white;
    font-family: WorkSans;
    height: 100%;

    @media (max-width: 960px) {
        height: 100%;
        width: 100%;
    }
}

.expanded-coupon {
    height: 100%;
    overflow-y: scroll;

    * {
        box-sizing: border-box;
    }

    &--in-page {
        transform: scale(0.8);
        width: 400px;
    }

    &__conditions {
        font-size: 0.7rem;
    }

    &__expiry-date {
        font-size: 0.7rem;
    }

    .header * {
        font-size: 18px;
    }

    .header,
    .footer {
        background-color: $application-color;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
            font-family: WorkSans;
            color: $white;
            text-transform: uppercase;
            margin: 0;
            font-weight: $light;

            span.emphasis {
                font-weight: bold;
            }
        }
    }

    .footer {
        .mobile-hidden {
            display: none;
        }

        .web-hidden {
            margin-top: auto;
            display: flex;
            justify-content: center;
            padding: 0 10%;
            margin-top: 0;

            img {
                width: 85px;
                height: 40px;
            }

            p {
                justify-self: center;
                margin-left: 30px;
                width: 60%;
                font-size: 15px;
                font-weight: bold;
                text-transform: none;
                text-decoration: none;
            }
        }
    }

    .body {
        padding: 15px 25px 0 25px;
        font-family: WorkSans;
        background-color: white;

        .body-row {
            display: flex;

            strong {
                color: $black;
            }
        }

        .body-row.body-top {
            display: flex;
            align-content: center;
            justify-content: space-between;
            position: relative;
            margin-bottom: 16px;

            p {
                color: $black;
                font-family: $Montserrat;
                font-weight: 500;
            }


            .star-image {
                width: 50px;
                cursor: pointer;
                align-self: flex-start;
            }

            .close-image {
                margin-left: 20px;
                width: 30px;
                align-self: flex-start;
                cursor: pointer;
            }

            .coupon-store {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 100px;
                }
            }
        }

        .body-row.coupon-details {
            border-top: 2px solid $red;
            border-bottom: 2px solid $red;
            padding: 3px 0;

            .details-wrapper {
                width: 100%;
                border-top: 6px solid $red;
                border-bottom: 6px solid $red;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 24px 0;

                h6 {
                    font-family: WorkSans;
                    font-weight: 400;
                    font-size: 6rem;
                    font-weight: 700;
                    color: $application-color;
                    margin: 0;
                    margin-top: -2rem;
                }

                p {
                    font-size: 14px;
                    width: 60%;
                    text-align: center;
                    color: $black;
                    margin: 0;
                    font-family: $WorkSans;
                }
            }
        }

        .body-row.qr {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
            width: 100%;

            img {
                max-width: 298px;
                max-height: 6rem;
            }

            .qr-text {
                margin-top: 1em;
                text-align: center;
                padding: 8px 8px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $black;
                font-family: $Montserrat;
                font-weight: $semibold;

                p {
                    margin: 0;
                    font-size: 0.7rem;
                    color: $white;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@include desktop {
    .coupon-modal-overlay {
        position: fixed;
        display: flex;
        align-content: center;
        justify-content: center;
        background-color: rgba($color: $black, $alpha: 0.2);
        outline: 0;
        width: 100%;
        height: 100%;
    }

    .coupon-modal {
        z-index: 0;
        width: 400px;
        right: auto;
        top: auto;
        outline: 0;
        align-self: center;

        .expanded-coupon {
            .body {
                padding: 25px 25px 0px 25px;
            }

            .footer {
                background-color: transparent;

                .mobile-hidden {
                    display: flex;

                    button {
                        cursor: pointer;
                        border: none;
                        border-radius: 4px;
                        background-color: $application-color;
                        font-family: $Montserrat;
                        text-transform: uppercase;
                        color: white;
                        padding: 15px 15px;
                        text-align: center;
                    }
                }

                .web-hidden {
                    display: none;
                }
            }
        }
    }

    .coupons {
        padding: 1rem 0;

        .coupons-body {
            .coupons-list {
                flex-direction: row;
                align-items: stretch;
                flex-wrap: wrap;
                width: 120%;

                .coupon-item {
                    width: 300px;
                    margin-right: 50px;
                    position: relative;
                }
            }
        }
    }
}

.faq-all {
  margin-top: 25px;

  @media (min-width: 960px) {
    padding-top: 50px;
  }

  .item {
    text-decoration: none;

    padding: 0.6em 1em;
    border: solid 1px $very-light-pink-two;
    border-bottom: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border-bottom: solid 1px $very-light-pink-two;
    }
  }

  .subcategory-name {
    font-family: $Montserrat;
    font-size: 14px;
    color: $black;
  }

  .subcategory-arrow-right {
    svg {
      height: 18px;
    }
  }

  h1 {
    font-family: $WorkSans;
    text-transform: uppercase;
    color: $application-color;
    font-weight: 900;
    text-align: center;
    padding: 1em 0;
    margin: 0;

    @include tablet {
      text-align: left;
    }
  }

  h3 {
    font-family: $WorkSans;
    font-weight: bold;
    font-size: 19px;
    color: $black;
  }
}

.faq-content {
  padding: 1em;
  background-color: $very-light-pink;

  a {
    color: $black;
  }


  @include tablet {
    max-width: 633px;
    background-color: $white;
    padding: 0 0 1em;
  }
}

.faq-category {

  .subcategory-logo-name {
    display: flex;
    align-items: center;
  }

  .subcategory-logo {
    margin-right: 1em;

    img {
      width: 24px;
      height: 24px;
    }
  }

}


.faq-header {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 6rem 1rem 1rem 1rem;
  box-sizing: border-box;

  .faq-category-title {
    background: $white;
    display: inline-block;
    font-family: $Montserrat;
    font-style: italic;
    font-size: 22px;
    font-weight: 300;
    box-sizing: border-box;
    padding: 1em 1.5em;
    text-align: left;

    h2 {
      margin-top: 0;
    }

    @include tablet {
      bottom: 1em;
      left: 1em;
      right: auto;
      margin-right: 1em;
    }
  }
}

.back-to-faq-categories {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 14px;
  font-family: $Montserrat;
  color: $black;


  .back-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px $very-light-pink-four solid;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    svg {
      height: 15px;
    }

    width: 37px;
    height: 37px;
    margin-right: 4px;
  }
}

.faq {
  padding: 1em;

  @media (min-width: 960px) {
    padding-top: 4rem;
  }

  h2 {
    font-family: $WorkSans;
    font-size: 19px;
    font-weight: bold;
    color: $black;
  }

  .faq-answer {
    padding: 1em;
  }

  .other-questions {
    margin-bottom: 2em; 

    .item {
      font-family: $Montserrat;
    }
  }
}

.faq-contact {
  display: flex;
  flex-direction: column;
  border: solid 1px $very-light-pink-two;
  padding: 0 17px;
  box-sizing: border-box;


  @include tablet {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 21px;
  }

  .border-bottom {
    margin: 1em 0;
    border-bottom: solid 1px $very-light-pink-two;
  }

  .contact-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    font-family: $Montserrat;
    font-size: 15px;
    color: $black;
    text-decoration: none;


    @include tablet {
      width: 180px;
      padding: 0;
      flex-direction: column;
      align-items: center;

      b {
        display: block;
      }

      span {
        font-size: 13px;
      }
    }

    &.no-border {
      border: 0;
    }

    p {
      margin: 0;
    }


    .contact-item-logo {
      svg {
        height: 37px;
        width: 37px;
      }

      @include tablet {
        order: 1;
      }
    }

    .contact-item-content {

      display: flex;
      align-items: center;


      @include tablet {
        flex-direction: column;
        order: 2;

        >div {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
      }
    }

    @include tablet {
      .mb-2 {
        margin-bottom: 2em;
      }
    }

  }

  .contact-item-arrow {
    order: 2;

    svg {
      height: 18px;
      width: 10px;
    }
  }

  @include tablet {
    >* {
      border-left: solid 1px $very-light-pink-two;
      border-right: solid 1px $very-light-pink-two;

      &.no-border {
        border: 0;
      }
    }

    .contact-form-mail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;

      .contact-item {
        flex-direction: row;

        &>div {
          display: flex;
          justify-content: space-between;
        }
      }

      .contact-item-content {
        order: 1;
        margin-right: 1em;

        >div {
          align-items: flex-start;
        }
      }
    }


  }
}
#login {
    max-width: 960px;
    margin: auto;
    padding-top: 2rem;
    position: relative;

    input {
        font-family: $Montserrat;
        font-weight: $light;
    }

    @media (min-width: 960px) {
        padding-top: 4rem;
    }

    @media (min-width: 960px) {
        display: flex;
    }

    a {
        color: $brownish-grey;
    }

    h1 {
        font-family: $WorkSans;
        font-weight: $heavy;
    }

    .register-div {
    }

    .reset-password-link {
        text-decoration: underline;
        font-family: $Montserrat;
        color: $brownish-grey;
        font-weight: $light;

        &:hover {
            cursor: pointer;
        }
    }

    .login-div {
        padding: 15px;

        button {
            width: 100%;
        }

        .login-form-group {
            flex-direction: column;

            .login {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 5%;

                img {
                    height: 1.5em;
                }

                p {
                    margin: 0 5%;
                }
            }

            .login.facebook {
                background-color: $facebook-blue;
            }

            .login.google {
                background-color: transparent;
                border: 1px solid $brownish-grey;

                color: $brown-grey;
            }
        }
    }

    @media (min-width: 960px) {
        form {
            width: 50%;
            display: inline-block;
            padding: 0 50px;
            box-sizing: border-box;
        }

        .register-div {
            width: 50%;
            display: inline-block;
            padding: 0 50px;
            box-sizing: border-box;
        }
    }

    .register {
        background-color: $application-color;
        color: white;
        padding: 16px 19px;
        font-family: $Montserrat;
        font-weight: $medium;
        font-size: 16px;
        text-decoration: none;
        border-radius: 4px;
        clear: both;
        display: inline-flex;
        align-items: center;
        margin-bottom: 35px;

        svg {
            margin-left: 10px;

            path {
                fill: $white;
            }
        }
    }

    .store-information {
        border: 1px solid $application-color;
        border-radius: 4px;
        display: block;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        color: $brown-grey;
        text-decoration: none;
        font-family: $Montserrat;
        font-weight: $medium;
        font-size: 16px;

        svg {
            width: 25px;
            height: auto;
            margin-right: 16px;
        }
    }
}

.filter-menu-search {
    padding: 0px 20px 20px 20px;
    display: flex;
    align-items: center;

    &__icon {
        height: 20px;
        width: auto;
        position: absolute;
        margin-left: 8px;

        path {
            fill: $application-color;
        }
    }

    &__brands {
        border: solid 1px $application-color;
        display: block;
        padding: 8px 8px 8px 35px;
        box-sizing: border-box;
        overflow: hidden;
        color: $brownish-grey;
        font-family: $Montserrat;
        width: 100%;

        &::placeholder {
            color: $very-light-pink-three;
            font-family: $Montserrat;
            font-weight: $light;
            font-size: 13px;
        }

        &:focus {
            outline: none;
        }
    }
}
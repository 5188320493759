.cancel-account {
    padding: 15px 15px;

    &__content {
        h2 {
            text-transform: uppercase;
            font-size: 2rem;
            font-family: $WorkSans;
            font-weight: $heavy;
            font-size: 3rem;
            margin: 0rem;
            margin-bottom: 0.5rem;
        }

        p {
            color: $brownish-grey;
        }
    }

    &__button {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        background-color: $application-color;
        padding: 15px;
        border: none;
        border-radius: 4px;

        p {
            display: flex;
            align-content: center;
            font-family: $Montserrat;
            font-weight: $medium;
            color: $white;
            margin: 0;

            svg {
                width: 1.5rem;
                height: auto;
                margin-left: 0.5rem;
            }
        }
    }
}
@import "detail-page-components/detail-header";
@import "detail-page-components/image-slider";
@import "detail-page-components/inner-logo-address";
@import "detail-page-components/detail-information";
@import "detail-page-components/detail-reviews";


#detail-content{
  margin-top: 25px;

  .promotions-banner {
    img {
      width: 100%;
    }
  }
}

@include desktop {
  #detail-content {
    padding-top: 4rem;
  }
}
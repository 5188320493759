.filter-menu-category-items {
    box-sizing: content-box;
    max-height: 155px;

    &--scroll {
        overflow-y: auto;
    }

    &__item {
        padding: 3px 20px;
        display: flex;
        align-items: center;

        p {
            display: inline;
            margin: 0 5px;
            font-size: 0.9rem;
            font-family: $Montserrat;
            font-weight: medium;
        }
    }

    &__char {
        margin: 0;
        padding: 3px 20px;
        background-color: $application-color-light;
    }

    .show-more {
        display: flex;
        padding: 10px 0px 0px 20px;
        display: inline;
        font-size: 0.9rem;
        font-family: $Montserrat;
        align-items: center;
        justify-content: center;

        &.fold-out {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            width: 12px;
            height: 16px;
            margin-left: 5px;
        }

        &:hover {
            cursor: pointer;
        }
    }
}
.coupon {

  .coupon-border {
    stroke: $application-color;
  }

  .coupon-header-background {
    fill: $application-color;
  }
  .coupon-header-text {
    fill: white;
    font-family: Montserrat;
    font-weight: $semibold;
    font-size: 14px;
  }

  .coupon-title {
    font-size: 3rem;
    fill: $application-color;
    font-family: $WorkSans;
    font-weight: $medium;
  }

  .coupon-subtitle {
    font-family: $Montserrat;
    font-weight: $regular;
    color: $brown-grey;
    text-align: center;
  }

  .star {
    width: 30px;
    height: 30px;
    display: flex;
    position: absolute;
  }
}

.coupon.disabled {
  .coupon-border {
    stroke: $brown-grey;
  }
  .coupon-header-background {
    fill: $brown-grey;
  }
  .coupon-title {
    fill: $brown-grey;
  }
}
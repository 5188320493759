.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.205);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 2000;

    &.open {
        display: flex;
    }

    .popup {
        background-color: $white;
        position: relative;
        padding: 50px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 100%;
        width: 50%;

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
        }

        h2 {
            color: $black;
            text-transform: uppercase;
            font-family: $WorkSans;
            margin-top: 0;
        }

        button {
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.08);
            }
        }

        input {
            margin-bottom: 15px;
        }

        .close {
            position: absolute;
            right: 25px;
            top: 25px;
            width: 20px;
            height: auto;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.categories-management {
    padding-top: 4rem;

    @media (max-width: 960px) {
        padding-top: 2rem;
    }

    .header {
        width: 800px;

        h1 {
            color: $black;
            text-transform: uppercase;
        }
    }

    .categories-management-body {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;

        .category-section {
            box-sizing: border-box;
            width: 48%;
            margin-bottom: 2rem;

            .section-header {
                display: flex;
                width: 100%;
                padding: 1rem;
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                box-sizing: border-box;
                transition: background-color 300ms ease;

                &:hover {
                    background-color: rgb(235, 235, 235);
                }

                h3 {
                    margin-top: 0;
                    margin-bottom: 0;
                }

                img.add {
                    margin-left: auto;
                    position: relative;
                    height: 25px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .section-body {
                ul {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    max-height: 30rem;
                    overflow-y: auto;

                    li {
                        width: 100%;
                        display: flex;
                        border-bottom: 1px black dotted;
                        padding: 1rem;
                        box-sizing: border-box;
                        transition: background-color 300ms ease;
                        line-height: 1rem;

                        &:hover {
                            background-color: rgb(235, 235, 235);
                        }

                        p {
                            margin: 0;
                            font-size: 1rem;
                        }

                        .order-arrow {
                            margin: 0;
                            height: 10px;
                            margin: 5px 5px 0 0;
                        }

                        .images {
                            margin-left: auto;
                            display: flex;
                            align-items: center;
                            
                        }

                        img {
                            margin-left: auto;
                            height: 15px;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        input {
                            position: relative;
                            margin-bottom: 3px;
                            border: 1px solid $application-color;
                            border-radius: 4px;
                            padding: 3px;
                            color: $brownish-grey;
                            width: 100px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.news-wrapper {
    .rdw-editor-toolbar,
    .news-editor {
        border: 1px solid $brownish-grey;
        border-radius: 4px;
    }

    .news-editor {
        padding: 5px;
        height: 200px;
    }
}

.manage-store-of-the-week {
  padding-top: 4rem;
  
  .body {
    .city-list {
      .city-row {
        .city-row-content {
          display: flex;
          flex-wrap: wrap;
          .store-item {
            display: flex;
            flex-direction: column;
            margin: 0 50px 50px 0;
            .image {
              position: relative;
              width: 260px;

              img.store-image {
                width: 260px;
                height: 160px;
                margin-bottom: 15px;
              }
              img.icon {
                width: 30px;
                height: 30px;
                position: absolute;
                top: 5px;
                right: 5px;
                cursor: pointer;
              }
            }
            p {
              width: auto;
              text-transform: uppercase;
              font-weight: bold;
              color: $application-color;
            }
          }
        }
      }
    }
  }
}

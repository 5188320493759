#search {
    position: relative;

    @media (min-width: $breakpoint-desktop) {
        position: absolute;
        width: 100%;
        bottom: 15px;
        max-width: $breakpoint-desktop;
        left: calc(50% - (#{$breakpoint-desktop} / 2));
        padding: 25px;
    }

    p {
        margin: 0;
    }

    input {
        font-family: $Montserrat;
        font-weight: $light;
        font-size: 18px;
        color: $brownish-grey;
    }

    .search-input {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;

        &.desktop {
            input {
                padding: 15px 15px 15px 60px;
            }

            svg {
                margin-left: 25px;
            }
        }

        input {
            border-radius: 28px;
            border: none;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.253);
            width: 100%;
            padding: 15px 15px 15px 50px;
            box-sizing: border-box;
            -webkit-appearance: none;

            &::placeholder {
                font-family: $Montserrat;
                font-style: italic;
                font-size: 14px;
            }

            &:focus {
                outline: none;
            }
        }

        svg {
            width: 25px;
            margin: 12px;
            height: auto;
            position: absolute;
            &:hover {
                cursor: pointer;
            }
        }
    }

    #search-mobile {
        height: 100%;
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        left: 0;
        top: 0;
        background-color: $white;
        z-index: 1001;
        overflow: scroll;

        &.hidden {
            z-index: 0;
        }

        input {
            border-radius: 28px;
            border: $application-color 2px solid;
            width: 100%;
            padding: 15px 15px 15px 50px;
            box-sizing: border-box;

            &::placeholder {
                font-family: $Montserrat;
                font-style: italic;
                font-size: 14px;
            }

            &:focus {
                outline: none;
            }
        }

        #search-top {
            background-color: $application-color-light;
            padding: 19px;

            #search-icon {
                width: 25px;
                margin: 12px;
                height: auto;
                position: absolute;
            }

            #search-title {
                position: relative;

                p {
                    font-size: 18px;
                    margin: 0 0 15px 0;
                    text-align: center;
                    font-family: $WorkSans;
                    font-weight: medium;
                    color: $brownish-grey;
                }

                #close-icon {
                    width: 18px;
                    height: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }

        .search-results {
            list-style: none;
            margin: 0;
            padding: 0;

            .no-results {
                font-family: $Montserrat;

                @media (max-width: 960px) {
                    padding: 20px;
                }

                &.hidden {
                    display: none;
                }
            }
    
            .result {
                width: 100%;
                padding: 10px 19px 10px 19px;
                border-bottom: solid $light-grey 1px;
                color: $brownish-grey;
                display: flex;
                justify-content: left;
                align-items: center;
                background-color: $white;
    
                .result-text {
                    margin-left: 14px;
    
                    .store-name {
                        margin-bottom: 0;
                    }
    
                    .address {
                        font-size: 12px;
                        margin-top: 0;
                    }
                }
            }
    
            .search-result-icon {
                width: 25px;
                height: auto;
    
                path {
                    fill: $application-color;
                }
            }
        }
    }

    #search-desktop {
        padding: 15px;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        z-index: 999;

        .search-results {
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 15px 0px;
            margin-top: 10px;
            border-radius: 28px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.253);
            box-sizing: border-box;
            z-index: 1003;
            background-color: $white;            

            path {
                fill: $brownish-grey;
            }
            
            .no-results {
                width: 100%;
                padding: 10px 19px 10px 19px;
                color: $brownish-grey;
                justify-content: left;
                align-items: center;
                box-sizing: border-box;
                font-family: $Montserrat;
                font-weight: $light;
            }

            .result {
                width: 100%;
                padding: 5px 50px;
                border-bottom: solid $light-grey 1px;
                color: $brownish-grey;
                display: flex;
                justify-content: left;
                font-family: $Montserrat;
                font-weight: $light;
                align-items: center;
                box-sizing: border-box;
                height: 50px;

                &:hover {
                    cursor: pointer;
                    background-color: $very-light-pink-two;
                }

                p {
                    display: inline-block;
                    margin: 0;
                }
    
                .result-text {
                    margin-left: 14px;
    
                    .store-name {
                        margin-bottom: 0;
                    }
                }
            } .result:last-child {
                border: none;
            }
    
            .search-result-icon {
                width: 25px;
                height: auto;
    
                path {
                    fill: $brownish-grey;
                }
            }
        }
    }
}
.button {
  margin-bottom: 15px;
}
.store-item {
  a,
  h3 {
    text-decoration: none;
    color: $black;
  }

  .edit-store {
    position: absolute;
    width: 20px;
    height: auto;
    top: 12px;
    right: 25px;

    &:hover {
      cursor: pointer;
    }
  }
  .edit-user {
    position: absolute;
    width: 20px;
    height: auto;
    top: 10px;
    right: 50px;

    &:hover {
      cursor: pointer;
    }
  }

  .warning {
    color: $red;
  }
}

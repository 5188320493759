.image-upload {
  .image-upload-button {
    cursor: pointer;
    width: 6em;
    height: 6em;
    background-color: $medium-grey;
    color: $white;
    border: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    &:hover {
      background-color: $dark-grey;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.image-size {
  padding: 0.5rem 0 0;
  color: $application-color;
  font-style: italic;

  &--faq {
    margin-bottom: 2rem;
  }
}


.standard-input {
    font-family: $Montserrat;
    font-weight: $light;
    font-size: 18px;
    padding: 15px 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.checkbox-container {
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: none;
    width: 25px;
    height: 25px;
    margin: 0;

    input[type=checkbox] {
        position: absolute;
        opacity: 0;
        z-index: 1001;
        width: 25px;
        height: 25px;

        &:checked + .checkmark {
            background-color: $application-color;
            border: none;

            svg {
                width: 70%;
                height: auto;
            }
        }
    }

    .checkmark {
        width: 100%;
        height: 100%;
        border: 1px solid $brownish-grey;
        box-sizing: border-box;
        border-radius: 3px;
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
    }
}
.personal-details {
  width: 90%;
  margin: 0 5%;
  padding: 1rem 0;

  h1 {
    color: $black;
    font-size: 1.6rem;
  }

  .details-header {
    h2 {
      text-transform: uppercase;
      color: $black;
      font-size: 1.8rem;
      font-family: $WorkSans;
      font-weight: $heavy;

      @include desktop {
        margin-top: 0;
      }
    }

    .subtitle {
      font-family: $Montserrat;
      font-weight: $light;
      color: $brownish-grey;
      font-size: 14px;
    }

    .errors {
      color: red;
    }
  }

  .details-body {
    .details-section {
      .section-header {
        h3 {
          font-family: $WorkSans;
          font-weight: $heavy;
          color: $black;
          text-transform: uppercase;
          font-size: 21px;

          .section-icon {
            display: inline-block;
            vertical-align: top;
            position: relative;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background-color: $application-color;

            p {
              position: absolute;
              color: $white;
              left: 5px;
              font-size: 5px;
              top: 0px;
            }
          }
        }
      }

      .section-body {
        .input-group {
          .input-group-header {
            h4 {
              text-transform: uppercase;
              margin-top: 40px;
              font-family: $WorkSans;
              color: $black;
              font-size: 1.2rem;
              margin-bottom: 20px;
            }
          }

          .input-group-body {
            .input-field-wrapper {
              width: 100%;
            }

            .input-field,
            .input-group-body.input-field {
              input {
                box-sizing: border-box;
                position: relative;
                color: $black;
              }

              input.submit-personal-changes {
                text-align: left;
                color: $white;
                background-color: $application-color;
                border: none;
              }

              input.submit-personal-changes:after {
                content: "->";
              }

              img {
                display: none;
              }

              img.submit-icon {
                display: block;
                position: relative;
                width: 22px;
                bottom: 1.6rem;
                left: calc(100% - 2rem);
              }
            }

            .input-field.validated {
              img {
                display: block;
                height: 0.75rem;
                bottom: 1.6rem;
                left: calc(100% - 2rem);
                position: relative;
              }
            }
          }
        }

        .input-group .dob {
          line-height: none;
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;

          .input-field {
            width: 25%;
          }

          select {
            width: 100%;
            appearance: none;
            border: 1px solid $brownish-grey;
            border-radius: 5px;
            padding: 10px 10px;
            line-height: 1.15;
            color: $black;
            background-color: white;
          }
        }

        .input-group .gender {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          .input-field {
            width: auto;
            margin: 0;

            input {
              width: auto;
              margin-right: 10%;
              opacity: 0;
              position: absolute;
            }

            input:checked + label,
            input:not(:checked) + label {
              position: relative;
              cursor: pointer;
              display: inline-block;
            }

            input + label:before {
              height: 16px;
              width: 16px;
              content: " ";
              display: inline-block;
              border: 1px solid $brownish-grey;
              border-radius: 50%;
            }

            input:checked + label:before {
              border: 1px solid $application-color;
            }

            input:checked + label:after,
            input:not(:checked) + label:after {
              width: 12px;
              height: 12px;
              top: 3px;
              left: 3px;
              position: absolute;
              content: " ";
              background-color: $application-color;
              border-radius: 100%;
            }

            input:checked + label:after {
              opacity: 1;
            }

            input:not(:checked) + label:after {
              opacity: 0;
            }

            label {
              margin: 0;
              margin-right: 15px;
            }

            p {
              display: inline;
              margin-left: 5px;
              vertical-align: top;
            }
          }
        }

        .input-group .hidden {
          display: none;
        }

        .input-group.password-change {
          border-top: 1px solid $brownish-grey;
          margin-top: 10px;
          margin-bottom: 5%;
          padding-top: 20px;
        }

        p.change-password {
          font-family: $Montserrat;
          font-weight: bold;
          font-size: 12px;
        }

        .repeat-password {
          margin-top: 5%;
        }
        .change-password {
          margin-top: 40px;
          width: auto;
          display: inline-block;
          text-transform: uppercase;
          border-bottom: 2px solid $black;
          padding-bottom: 3px;
          cursor: pointer;
        }
      }
    }

    .details-section .mobile-hidden {
      display: none;
    }

    .details-section .web-hidden {
      display: block;
    }
  }

  p.disclaimer {
    font-family: $Montserrat;
    font-weight: $light;
    color: $brownish-grey;
    font-size: 14px;
    a {
      color: $brownish-grey;
    }
  }
}

@include desktop {
  .personal-details {
    width: 90%;
    margin-left: 5%;
    padding: 4rem 0;

    .details-body {
      .details-section {
        .input-group {
          .input-group-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            .input-field {
              flex: 1 1 auto;
              margin-right: 5%;

              .submit-personal-changes {
                cursor: pointer;
              }
            }

            .input-field-wrapper {
              width: auto;
              margin-right: 5%;

              .input-field {
                margin-right: 0;
              }
            }
          }
        }

        .input-group.password-change {
          .input-group-body {
            width: 45%;
            flex-direction: column;

            .input-field {
              margin-bottom: 5%;
            }

            .input-field:last-child {
              margin-bottom: 0;
            }
          }

          .repeat-password {
            margin-top: 0;
          }
        }
      }

      .details-section .mobile-hidden {
        display: block;
      }

      .details-section .web-hidden {
        display: none;
      }
    }
  }
}

//1rem = 16px
.cookie {
    position: fixed;
    z-index: 1001;
    bottom: 0;
    background-color: white;
    box-shadow: 0px -2px 10px 0 rgba(0, 0, 0, 0.28);
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    @media (max-width: 960px) {
        padding: 0 1rem;
    }

    .wrapper {
        padding: 2rem 0;

        @include desktop {
            max-width: $breakpoint-desktop;
        }

        h1 {
            color: $black;
            font-size: 1.3rem;
            font-weight: $medium;
            font-family: $WorkSans;
        }

        h5 {
            font-size: 0.9rem;
            font-family: $Montserrat;
            font-weight: $semibold;
            color: $brownish-grey;
            margin: 0;
        }

        p {
            font-size: 0.9rem;
            font-family: $Montserrat;
            font-weight: $light;
            color: $brownish-grey;
            margin-bottom: 32px;
        }

        .button-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .accept-button {
                font-family: $Montserrat;
                background-color: $application-color;
                padding: 14px 21px;
                color: white;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
                border: none;
            }

            .decline-button {
                font-family: $Montserrat;
                background-color: $medium-grey;
                padding: 14px 21px;
                color: white;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
                border: none;
            }
        }

        a {
            display: inline;
            color: $brownish-grey;
            font-size: 0.9rem;
            text-decoration: underline;
            font-family: $Montserrat;
            font-weight: $light;
        }
    }
}

#coupon-management {
    padding: 1rem;
    
    h1,
    h2 {
        color: $black;
        font-family: $WorkSans;
    }

    h1 {
        text-transform: uppercase;
        font-weight: $heavy;
        font-size: 2rem;
    }

    h2 {
        font-weight: $medium;
        font-size: 1.5rem;
    }

    p {
        font-family: $Montserrat;
    }

    .coupon {
        position: relative;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    .split-line {
        width: 100%;
        height: 2px;
        background-color: $very-light-pink-two;
        margin: 30px 0px;
    }

    .coupon-cont {
        display: flex;
        flex-wrap: wrap;
        column-count: 3;
        flex-direction: row;

        &__times-used {
            font-weight: $bold;
        }

        .coupon-item {
            margin-right: 20px;
            display: inline-block;

            .coupon-title {
            }

            .coupon-city,
            .coupon-valid {
                display: none;
            }
        }
    }

    .edit-links {
        display: flex;

        p, a {
            text-transform: uppercase;
            font-family: $Montserrat;
            color: $application-color;
            font-size: 15px;
            margin-right: 10px;
            cursor: pointer;
        }
    }

    .button-link {
        background-color: $application-color;
        display: inline-flex;
        align-items: center;
        min-width: 0;
        color: $white;
        padding: 14px;
        font-family: $Montserrat;
        font-weight: $medium;
        border-radius: 4px;

        &:disabled {
            background-color: $brownish-grey;
        }

        svg {
            height: 14px;
            width: auto;
            margin-left: 10px;

            path {
                fill: $white;
            }
        }
    }
}

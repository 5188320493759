.fashion-hotspots-management {
  padding-top: 4rem;
  
  .body {
    .section {
      form {
        .row {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: auto;
          margin-top: 20px;
          label {
            position: relative;
            margin: 0 0 10px 0;
          }
          input {
            width: 300px;
            margin-left: 45px;
          }
          img {
            width: 30px;
            height: 30px;
            align-self: center;
            margin-right: 15px;
          }
        }
        .social-media-row,
        .selling-point-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          .input {
            input {
              margin-left: 0;
            }
            display: flex;
            flex-direction: column;
          }
          .selling-point-input {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .image-select {
            height: auto;
            margin-right: 15px;
            align-self: center;
            button.image-upload-button {
              background-size: cover;
              background-position: center;
              background-color: transparent;
              border: 1px solid $grey;
              width: 30px;
              padding: 0;
              height: 30px;
            }
          }
        }
        .selling-point-row {
          position: relative;
          img.delete {
            position: absolute;
            top: 0;
            width: 15px;
            left: 350px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

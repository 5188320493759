.city-overview-store {
    flex-grow: 1;
    min-width: 0;
    transition: transform 0.3s ease;

    @media (min-width: 960px) {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 959px) {
        padding: 35px 0px 35px 0px;
        border-bottom: 2px solid $very-light-pink-two;
    }

    &:hover {
        transform: scale(1.02);
    }

    &__image {
        background-position: center;
        background-size: cover;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 959px) {
            height: 200px;
            border-radius: 5px;
            margin-bottom: 10px;
        }

        @media (min-width: 960px) {
            height: 200px;
            width: 300px;
            flex-shrink: 0;
        }
    }

    &__rating-container {
        display: flex;
        box-sizing: border-box;
        align-self: flex-end;
    }

    &__name-location-container {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        min-width: 0;
        justify-content: space-between;
    }

    &__location {
        display: flex;
        padding: 0.1rem 0.3rem;
        border: 2px solid $very-light-pink-three;
        box-sizing: border-box;
        float: left;

        &-container {}

        p {
            margin-bottom: 0;
            margin-left: 0.2rem;
            display: block;
        }

        svg {
            width: 0.5rem;
            height: auto;
            align-self: flex-start;
        }
    }

    &__name,
    &__name a,
    &__name p {
        font-size: 21px;
        font-family: $WorkSans;
        font-weight: $medium;
        color: $black;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: inherit;
        white-space: nowrap;
        box-sizing: border-box;
        margin-bottom: 10px;

        &-container {
            display: flex;
        }

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 959px) {
            width: 100%;
            margin: 0;
        }
    }

    &__info {
        width: 100%;

        @media (min-width: 960px) {
            padding: 10px 20px;
            box-sizing: border-box;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            min-width: 0;
        }

        .title-coupons {
            background-color: $orangey-red;
            color: white;
            font-family: $WorkSans;
            font-weight: $regular;
            font-size: 11px;
            display: inline-block;
            padding: 5px;
            vertical-align: middle;
            line-height: 11px;
        }

        &-header {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }

    .information {
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        line-clamp: 5;
        margin-bottom: 10px;
        break-inside: avoid;
        font-size: 17px;
    }

    .coupons {
        padding: 0;

        .coupon {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 0.9rem;
                font-family: $Montserrat;
                font-weight: $light;
                color: $brownish-grey;
                width: 90%;
                margin: 0;
            }

            svg {
                height: 18px;
                width: 18px;
                margin-right: 5px;

                path {
                    fill: $orangey-red;
                }

                polygon {
                    fill: $orangey-red;
                }

                rect {
                    fill: $orangey-red;
                }
            }
        }
    }


    &__rating-wrapper {
        @media (max-width: 960px) {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    &__rating {
        @include desktop {
            text-align: center;
            flex-grow: 1;
            border-bottom: $very-light-pink-two 1px solid;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        @media (max-width: 960px) {
            &-number {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: flex-end;

                .city-overview-store__actual-rating {
                    margin-bottom: -4px;
                }
            }
        }

        p {
            display: inline-block;
            margin: 0;
        }
    }

    &__actual-rating {
        font-family: $WorkSans;
        font-weight: $medium;
        color: $brownish-grey;
        margin-bottom: 0;
        justify-self: flex-end;

        &:hover {
            cursor: pointer;
        }
    }

    &__max-rating {
        font-size: 0.8rem;
        color: $brownish-grey;
        font-family: $WorkSans;
        font-weight: $medium;
        margin-bottom: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &__number-of-ratings {
        font-family: $WorkSans;
        font-weight: $light;
        color: $brown-grey;
        white-space: nowrap;
        display: block;
        width: 100%;
    }

    .favorite-store-icon {
        width: 1rem;
        vertical-align: top;
        margin-left: 0.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    .favorite-store-icon-grey {
        width: 1rem;
        vertical-align: top;
        margin-left: 0.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__login {
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 15px;
        gap: 5px;

        @include desktop {
            padding-top: 0px;
        }

        &--dummy {
            b {
                color: $brownish-grey;
            }
        }

        &-icon {
            width: 20px;
            height: auto;
            margin-right: 8px;
        }

        .login {
            display: flex;
            flex-direction: row;
        }

        .information {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            margin-bottom: 10px;
            break-inside: avoid;
        }

        a {
            display: inline-block;
            font-family: $Montserrat;
            font-weight: $light;
            font-size: 0.9rem;
            margin: 0;
            text-decoration: none;
            color: $brownish-grey;

            b {
                color: $application-color;
                font-weight: $medium;
            }
        }
    }

    &__side-info {
        margin: 10px 0;
        padding: 0px 20px;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-left: $very-light-pink-two 1px solid;

        .navigator {
            flex-grow: 1;
        }
    }

    .navigate {
        margin-top: 1rem;
        height: 50px;
        width: 100%;
        font-family: $Montserrat;
        font-size: 16px;
        font-weight: $medium;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 959px) {
            border-radius: 8px;
            border: none;
            background-color: $application-color;
            color: white;

            &.dummy {
                background-color: $brownish-grey;
            }


            svg {
                path {
                    fill: #FFF;
                }
            }
        }

        @media (min-width: 960px) {
            text-decoration: none;
            color: $application-color;
            font-size: 13px;
        }

        svg {
            height: 25px;
            width: auto;
            margin-left: 8px;
        }
    }

    &--dummy {
        &:hover {
            transform: none;
        }

        .city-overview-store__image {
            cursor: default;
        }

        * {
            color: $brown-grey;
            fill: $brown-grey;

            a {
                &:hover {
                    cursor: default;
                }
            }
        }
    }
}
#not-found-page {
    font-family: $WorkSans;
    font-weight: $bold;
    padding: 20px;

    h1 {
        color: $application-color;
        font-size: 38px;
    }

    p {
        font-family: $Montserrat;
        font-weight: $light;

        a {
            color: $application-color;
            text-decoration: none;
        }
    }
}
.image-slider {
  .store-detail-image {
    //max-width: 635px;
    max-width: 100vw;

    .image-container {
      img {
        width: 100%;
        //object-fit: cover;
        //max-height: ;
        @include tablet {
          max-height: 358px;
        }
      }
    }


    .custom-arrow {
      position: absolute;
      top: 50%;
      padding: 0;
      z-index: 2;
      width: 30px;
      height: 30px;
      background: $black;
      border: 2px solid $white;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0.85;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);

      svg {
        path {
          fill: $white;

        }
        width: 14px;
        height: 8px;

        @include tablet {
          width: 18px;
          height: 10px;
        }
      }

      @include tablet {
        width: 40px;
        height: 40px;
      }

      &.arrow-left {
        left: 15px;

        svg {
          transform: translateX(-1px) rotate(90deg);
        }

        @include tablet {
          left: 25px;
        }
      }

      &.arrow-right {
        right: 15px;

        @include tablet {
          right: 25px;
        }

        svg {
          transform: translateX(1px) rotate(-90deg) ;
        }
      }


    }
  }
}

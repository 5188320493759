@import "./filter-menu-components/filter-menu-category";

.filter-menu {
    color: $black;
    min-height: 0;

    @include desktop {
        background-color: #e0e0e044;
        margin-right: 5px;
    }

    .checkbox-container {
        &:hover {
            cursor: pointer;
        }
    }
    
    &__desktop-header {
        background-color: $background-color-primary;
        padding: 12px 16px;
        color: $text-color-primary;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            margin: 0;
            font-family: $Montserrat;
            font-weight: $medium;
            font-size: 20px;
        }
    }

    &__restore-button {
        background-color: #00a09b21;
        font-family: $Montserrat;
        font-weight: $regular;
        font-size: 14px;
        text-transform: lowercase;
        border: none;
        padding: 5px;
        border-radius: 2px;

        @include desktop {
            background-color: $white;
        }

        @media (max-width: 959px) {
            margin-right: 10px;
        }

        svg {
            height: 10px;
            width: auto;

            path {
                fill: $brownish-grey;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    input[type=checkbox] {
        &:hover {
            cursor: pointer;
        }
    }

    input[type=checkbox]:checked~.checkmark {
        &:hover {
            cursor: pointer;
        }

        svg {
            &:hover {
                cursor: pointer;
            }

            path {
                fill: $white;
            }
        }
    }

    .checkmark {
        &:hover {
            cursor: pointer;
        }

        svg {
            path {
                fill: rgba(0, 0, 0, 0);
            }
        }
    }

    .right-side {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .sort__arrow {
        width: 20px;
        height: auto;
        float: right;
        margin-top: 5px;
        margin-right: 25px;
        transform: rotate(180deg);
    }

    .sort-option {
        display: flex;
        align-items: center;
        padding: 3px 20px;

        p {
            margin: 0 0 0 5px;
        }

        .radio-container {
            position: relative;

            input {
                height: 25px;
                width: 25px;
                position: absolute;
                z-index: 1001;
                opacity: 0;
            }

            input[type=radio]:checked~.radio-outline {
                .radio-ellipse {
                    opacity: 1;
                }
            }

            .radio-outline {
                height: 25px;
                width: 25px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: solid 1px $brownish-grey;

                .radio-ellipse {
                    height: 17px;
                    width: 17px;
                    border-radius: 100%;
                    background-color: $application-color;
                    opacity: 0;
                }
            }
        }
    }
}
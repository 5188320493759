@import "city-overview-components/pagination";
@import "city-overview-components/sort-dropwdown";
@import "city-overview-components/city-overview-store";
@import "city-overview-components/filtermenu";
@import "../base/colors";

.city-overview {
  @include width-xl;
  @include desktop {
    padding-top: 50px;
  }

  .dummy {
    * {
      color: $brown-grey;
      fill: $brown-grey;

      &:hover {
        cursor: auto;
      }
    }
  }

  &__results-header {
    display: flex;
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    @media (min-width: 960px) {
      padding-left: 275px;
      padding-right: 0px;
    }

    p {
      display: inline;
      margin: 0;
    }

    p {
      color: $brownish-grey;
      font-family: $Montserrat;
      font-weight: $medium;
      font-size: 14px;
    }
  }

  &__desktop-wrapper {
    display: flex;
    width: 100%;
  }

  &__pages {
    min-width: 0;
    width: 100%;
  }

  &__stores {
    box-sizing: border-box;

    @media (max-width: 959px) {
      padding: 0px 15px;

      .store:first-child {
        padding: 0px 0px 35px 0px;
      }
    }

    @media (min-width: 960px) {
      padding-left: 15px;
      flex-grow: 1;
      min-width: 0;
      width: 100%;
    }
  }

  &__results {
    @include desktop {
      display: flex;
      min-width: 0;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  .city-image-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-color: white;

    @media (min-width: 960px) {
      margin-bottom: 20px;
      padding: 20px;
      height: 300px;
      padding-right: 0;

      img {
        left: 0;
        position: absolute;
      }
    }

    @media (max-width: 959px) {
      height: 150px;
    }

    .city-description {
      background-color: $background-color-secondary;
      padding: 20px;
      z-index: 1;
      border-radius: 20px 0px 20px 0px;
      max-width: 35%;
      margin: 0;
      font-family: $WorkSans;
      font-weight: $light;
      font-size: 20px;
    }
  }

  &__mobile-overlay {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.212);
    height: 100%;
    position: fixed;
    z-index: -1;
    opacity: 0;
    top: 0;
    transition: right 0.5s ease, opacity 0.5s ease, z-index 0s ease 0.5s;

    &.open {
      transition: right 0.5s ease, opacity 0.5s ease, z-index 0s ease 0s;
      z-index: 1001;
      opacity: 1;
      right: 0;
    }
  }

  &__filter-menu-wrapper {
    position: fixed;
    right: -100%;
    width: 80%;
    background-color: $white;
    top: 0;
    transition: right 0.5s ease;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1001;
    max-height: 100%;

    &.open {
      right: 0;
    }

    #close {
      height: 20px;
      width: 20px;
      float: right;
    }

    a {
      padding: 15px 0px;
      font-family: $WorkSans;
      font-weight: $book;
      color: $black;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-bottom: 1px solid $light-grey;
      padding-left: 20px;
      padding-right: 20px;

      svg {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }

  &__filter-menu-header {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    padding-left: 25px;
    padding-right: 25px;

    p {
      width: 100%;
      margin: 0;
      font-size: 18px;
      text-align: left;
      color: $application-color;
      font-family: $WorkSans;
      font-weight: $book;
    }
  }

  @media screen and (max-width: 959px) {
    &__filter-menu-header {
      justify-content: space-between;

      p {
        width: auto;
      }

      #close {
        width: 20px;
        height: auto;
        align-self: center;
        justify-content: flex-start;
      }
    }
  }

  .filter-city {
    position: relative;

    &__desktop-image {
      left: 0;
      position: absolute;
      width: 60%;
    }

    &__desktop-img {
      height: 340px;
      top: -360px;
      position: absolute;
      width: 100%;
      background-position: center;
      background-size: cover;
    }

    &__desktop-fade {
      height: 340px;
      top: -360px;
      position: absolute;
      width: 100%;
      background-color: white;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 90%);
    }

    .city-name {
      position: absolute;
      right: 15px;
      top: 55px;
      background-color: $background-color-primary;
      color: $text-color-primary;
      padding: 1px 8px 8px 8px;
      font-family: $WorkSans;
      font-weight: $regular;
      font-size: 21px;

      @media (max-width: 960px) {
        padding: 8px;
      }
    }

    div {
      background-position: center;
      background-size: cover;
      background-position: 0 0;
      background-repeat: no-repeat;
    }

    .city-description {
      @media (max-width: 959px) {
        background-color: $background-color-secondary;
        padding: 20px;
        z-index: 1;
        border-radius: 20px 20px 20px 20px;
        margin: 1rem;
        font-family: $WorkSans;
        font-weight: $light;
        font-size: 19px;
      }

      .city-description-text {
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
      }
    }

    .city-description-bottom-text {
      text-align: right;
      margin-top: 10px;
      margin-bottom: 0;
      color: $text-color-primary;
      font-size: 18px;
      font-weight: bold;

      svg {
        margin-bottom: -4px;
        width: 22px;
        height: 20px;
      }

    }

    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  .filter-button {
    border: 1px solid $very-light-pink-three;
    background-color: white;
    font-family: $Montserrat;
    font-weight: $medium;
    font-size: 14px;
    padding: 10px;
    border-radius: 3px;
    color: $brownish-grey;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 1rem;
      height: auto;
      margin-right: 0.5rem;
    }
  }

  .desktop-title {
    font-family: $WorkSans;
    font-weight: $regular;
    font-size: 25px;
    margin-bottom: 15px;
    color: $application-color;
    padding-left: 270px;
  }

  .store-of-the-month-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .maps-placeholder {
      flex-shrink: 0;
      width: 250px;
      height: 260px;
      flex-grow: 1;
      min-height: 0;
      margin-right: 20px;

      iframe {
        width: 250px;
        height: 260px;

        .place-card {
          display: none;
        }
      }
    }

    .store-of-the-month {
      min-width: 0;
      padding: 15px;
      background-color: $application-color-light;
      width: 100%;

      .title {
        font-family: $Montserrat;
        font-weight: $medium;
        font-size: 18px;

        &:hover {
          cursor: pointer;
        }
      }

      .city-overview-store {
        border-bottom: none;
        background-color: white;
        padding: 15px;
        transition: transform 0.3s ease;

        @media (min-width: 960px) {
          margin: 0;
        }

        &:hover {
          transform: scale(1.02);
        }
      }
    }
  }
}
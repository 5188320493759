
@mixin tablet {
  @media only screen and (min-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}


// Width mixins to extend the size of content beyond the "main" wrapper

@mixin width-full {
  @media only screen and (min-width: #{$breakpoint-desktop}) {
    margin-left: calc((100vw - #{$breakpoint-desktop}) / -2);
    margin-right: calc((100vw - #{$breakpoint-desktop}) / -2);
  }
}

@mixin width-xl {
  @media only screen and (min-width: #{$breakpoint-desktop}) {
    margin-left: calc((100vw - #{$breakpoint-desktop}) / -2);
    margin-right: calc((100vw - #{$breakpoint-desktop}) / -2);
    padding-left: 15px;
    padding-right: 15px;
  }
  @media only screen and (min-width: #{$breakpoint-desktop-xl}) {
    margin-left: calc((#{$breakpoint-desktop-xl} - #{$breakpoint-desktop}) / -2);
    margin-right: calc((#{$breakpoint-desktop-xl} - #{$breakpoint-desktop}) / -2);
  }
}

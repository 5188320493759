.shopper-reviews-page {
    h2 {
        color: $black;
        text-transform: uppercase;
        font-family: $WorkSans;
    }

    .back-button {
        display: flex;
        align-items: center;
        color: $black;
        text-transform: uppercase;
        font-family: $WorkSans;

        svg {
            transform: rotate(-180deg);
            margin-right: 15px;
            height: 17px;
            width: auto;
        }
    }

    .review {
        padding: 20px;
        border-left: 3px solid $application-color;
        margin-bottom: 20px;

        .review-header {
            display: flex;
            justify-content: space-between;

            p,
            h3 {
                font-size: 20px;
                margin-top: 0;
                color: $black;
                text-transform: uppercase;
                font-family: $WorkSans;
            }
        }

        input {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            margin-bottom: 10px;
            font-size: 18px;
            font-family: $Montserrat;
        }

        .review-content {
            p {
                font-size: 18px;
                font-family: $Montserrat;
            }
        }
    }

    .button {
        margin-right: 15px;
    }

    .edit-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
            display: flex;
            align-items: center;
        }

        p {
            text-transform: uppercase;
            color: $application-color;
            margin-bottom: 0;
            margin-right: 15px;
            color: $orangey-red;

            &:hover {
                cursor: pointer;
            }
        }

        svg {
            width: 20px;
            height: auto;
            margin-right: 15px;
            cursor: pointer;
        }
    }

    .popup-div {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        top: 0;
        left: 0;

        .close {
            position: absolute;
            width: 20px;
            height: auto;
            top: 10px;
            right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .popup {
            background-color: $white;
            position: absolute;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h2 {
                color: $black;
                font-family: $Montserrat;
            }
        }
    }
}

#admin-page-content {
    padding-top: 4rem;
    
    .title {
        font-family: $WorkSans;
        text-transform: uppercase;
        color: $black;
    }

    button {
        background-color: $application-color;
        border: none;
        padding: 15px 15px 20px 15px;
        color: $white;
        font-family: $WorkSans;
        border-radius: 4px;
    }

    .page-content-list {
        .page-content-list-items {
            &.close {
                display: none;
            }
        }

        h1 {
            display: flex;
            align-items: center;

            svg {
                margin-top: 15px;
                margin-left: 10px;
                width: 25px;
                height: auto;
                cursor: pointer;

                &.open {
                    transform: rotate(180deg);
                }
            }
        }

        .page-content-editor {
            margin-bottom: 15px;

            textarea {
                width: 100%;
                height: 8em;
            }
        }
    }
}

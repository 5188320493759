#register {
    max-width: 960px;
    margin: auto;
    padding: 2rem 0;

    @include tablet {
        padding: 4rem 0;
    }

    @media (min-width: 960px) {
        display: flex;
    }

    form {
        padding: 15px;
        margin-bottom: 30px;
        box-sizing: border-box;

        @media (min-width: 960px) {
            width: 50%;
            display: inline-block;
        }

        h1 {
            font-size: 2rem;
        }

        h2 {
            font-family: $WorkSans;
            font-size: 1rem;
            color: $black;
        }

        input {
            width: 100%;
            box-sizing: border-box;
            margin: 10px 0;
            font-family: $Montserrat;
            font-weight: $light;

            &::placeholder {
                font-family: $Montserrat;
                font-weight: $light;
                color: $brown-grey;
                font-size: 14px;
            }
        }

        select {
            font-family: $Montserrat;
            font-weight: $light;
            padding: 7px 10px;
        }

        .date {
            display: flex;
            justify-content: space-between;

            input {
                width: 30%;
            }
        }

        button[type=submit] {
            display: inline-flex;
            align-items: center;

            svg {
                margin-left: 10px;

                path {
                    fill: $white;
                }
            }
        }

        .terms-and-conditions {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            margin-top: 1rem;

            p {
                margin-bottom: 0;
                font-family: $Montserrat;
                font-weight: $light;
                color: $brownish-grey;
            }

            .checkbox-container {
                margin-right: 1rem;
                cursor: pointer;
            }
        }
    }

    .register-div {
        padding-top: 15px;
        box-sizing: border-box;

        @media (min-width: 960px) {
            width: 50%;
            display: inline-block;
        }

        @media (max-width: 959px) {
            border-top: 1px solid $very-light-pink-two;
        }
    }
}
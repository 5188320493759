.navigator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .navigate-title {
    color: $application-color;
    display: flex;
    align-items: center;
    font-size: 1em;
    text-decoration: none;
    margin-bottom: 5px;

    svg {
      margin-left: .5em;
      width: 1.5em;
      height: 1.5em;

      path {
        fill: $application-color;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .navigate-distance {
    color: $brown-grey;

    svg {
      path {
        fill: $brown-grey;
      }

      margin-left: .5em;
      width: 1rem;
      height: 1rem;
    }
  }
}
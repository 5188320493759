.store-clothing-categories {
  padding-top: 4rem;

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
  
  label {
    font-family: $Montserrat;
    font-size: 16px;
    font-weight: 300;
    color: $brownish-grey;
  }

  .category-items {
    display: flex;
    flex-wrap: wrap;
    column-count: 3;
    column-width: auto;
  }

  .search-container {
    margin-bottom: 45px;
  }

  .clothing-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 33.3%;
    padding-right: 50px;
    break-inside: avoid;
    box-sizing: border-box;

    p {
      margin-bottom: 0;
    }

    input {
      &:hover {
        cursor: pointer;
      }
    }

    .category-title {
      margin: 0;
    }

    label {
      margin: 0;
    }

    input {
      
    }
  }

  button {
    margin: 20px 0 40px;
  }
}
.news {
    background-color: $very-light-pink;
    margin-top: 25px;
    padding-bottom: 15px;
    padding-top: 2rem;

    @media (min-width: 960px) {}

    .page-title {
        font-family: $WorkSans;
        font-weight: $heavy;
        font-size: 38px;
        color: $application-color;
        padding: 35px 15px;
        margin: 0;
    }

    .last-item {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 300px;
        background-position: center;
        background-size: cover;

        .last-item-title {
            font-family: $WorkSans;
            font-weight: $regular;
            font-size: 24px;
            color: $black;
            padding: 2px 10px 10px 10px;
            background-color: $white;
            margin: 15px;
        }
    }

    .show-more {
        justify-content: center;
        margin: 0;
        font-family: $Montserrat;
        font-weight: $regular;
        font-size: 15px;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &--less {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            width: 12px;
            margin-left: 10px;
            height: auto;
        }
    }

    .news-cards {
        display: flex;
        flex-wrap: wrap;
    }

    .news-card-container {
        padding: 0 15px;
        margin-bottom: 15px;
        box-sizing: border-box;
        width: 33.33%;

        &:hover {
            cursor: pointer;
        }

        @media (max-width: 960px) {
            width: 100%;
        }

        &:last-child {
            margin-bottom: 0px;
        }

        .news-card {
            background-color: white;
            padding: 5px;

            transition: transform 0.3s ease;
            box-shadow: rgba(124, 124, 124, 0.349) 0px 0px 10px;

            &:hover {
                transform: scale(1.05);
            }

            .image {
                width: 100%;
                height: 150px;
                overflow: hidden;
                background-size: cover;
            }

            p {
                padding: 10px;
            }

            .item-title {
                font-family: $WorkSans;
                font-size: 17px;
                color: $black;
                box-sizing: border-box;
                margin-bottom: 0px;
            }

            .date {
                display: inline-block;
                font-family: $Montserrat;
                font-weight: $regular;
                color: $brownish-grey;
                font-size: 13px;
                text-transform: uppercase;
                margin-bottom: 0px;
            }

            .label {
                display: inline-block;
                font-family: $Montserrat;
                font-weight: $medium;
                color: $application-color;
                font-size: 13px;
                text-transform: uppercase;
                margin-bottom: 0px;
            }
        }
    }
}
.shopper-review-form {
  padding: 34px 15px;

  @include tablet {
    padding: 0.5rem;
  }

  @include desktop {
    padding: 4rem 1rem;
  }

  h1 {
    text-transform: uppercase;
    font-size: 44px;
    font-weight: 900;
    margin-bottom: 1em;
  }

  h3 {
    text-transform: uppercase;
    font-family: $WorkSans;
    font-size: 17.5px;
    font-weight: bold;
    padding: 0;
    margin-top: 0;
    margin-bottom: 1em;

    color: $black;

    @include tablet {
      font-size: 20px;
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 19px;

    svg {
      width: 26px;
      height: 18px;
      margin-left: 20px;

      path {
        fill: $white;
      }
    }
  }

  form {
    & > * {
      box-sizing: border-box;
    }

    @include tablet {
      max-width: 60%;
    }
  }

  input,
  textarea {
    border-radius: 4px;
    border: solid 1px $brownish-grey;
    width: 100%;

    padding: 10px 20px;
    margin-bottom: 3rem;
  }

  .required {
    color: $orangey-red;
  }

  .dv-star-rating-star {
    margin-right: 1em;
  }

  .review-store-info {
    display: flex;
    font-family: $Montserrat;
    align-items: center;

    margin-bottom: 3rem;

    .close-icon {
      width: 15px;
      height: 15px;
      margin-left: 15px;
      margin-top: 2px;

      &:hover {
        cursor: pointer;
      }
    }

    .info-div {
      display: flex;
    }

    .store-icon {
      width: 35px;
      height: 35px;
      margin-right: 11px;
    }

    .store-name {
      font-size: 18.5px;
      font-weight: 600;
      color: $application-color;
    }

    .store-address {
      font-size: 14.5px;
      font-weight: 300;
      line-height: 1.45;

      color: $brownish-grey;
    }
  }

  .store-rating {
    &__mobile {
      @media screen and (min-width: 960px) {
        display: none;
      }

      select {
        background-color: $white;
        padding: 10px 10px 10px 10px;
        width: 100%;
        border: 1px $black solid;
        border-radius: 5px;
        color: $brownish-grey;

        option {
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        }
      }
    }

    &__desktop {
      @media screen and (max-width: 960px) {
        display: none;
      }
      display: flex;

      label {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }

      input {
        width: 100%;
        margin: 6px 0 2px;
        cursor: pointer;
      }
    }

    margin-bottom: 2rem
  }

  .search-results {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 15px;

    .store-list {
      position: absolute;
      z-index: 1001;
      border: 1px solid $brownish-grey;
      border-top: none;
      width: 100%;
      box-sizing: border-box;

      .store-search-result {
        background-color: white;
        padding: 8px;
        box-sizing: border-box;

        p {
          margin-bottom: 0;
        }

        .store-name {
          font-size: 18.5px;
          font-weight: 600;
          color: $application-color;
        }

        .store-address {
          font-size: 14.5px;
          font-weight: 300;
          line-height: 1.45;

          color: $brownish-grey;
        }

        &:hover {
          cursor: pointer;
          background-color: $light-grey;
        }
      }
    }
  }
}

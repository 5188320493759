//Colors
$black: #2e2d2c;
$dark-grey: #888;
$medium-grey: #bbb;
$light-grey: #dddddd;
$lightest-grey: #eeeeee;
$white: white;
$red: red;
$teal-blue: #00a09b;
$teal-blue-light:#c3d7da;
$brownish-grey: #6c6c6c;
$grey: #3f3f3f;
$light-grey: #e0e0e0;
$very-light-grey: #e3e3e3;
$brown-grey: #969696;
$cobalt-blue-66: rgba(0, 41, 160, 0.66);
$very-light-grey: #e3e3e3;
$greyblue: #6bc0bd;
$orangey-red: #ff322d;
$facebook-blue: #4266b2;
$error-color: $red;
$background-color-content: $lightest-grey;
$background-color: $white;
$white: #ffffff;
$cobalt-blue-66: rgba(0, 41, 160, 0.66);
$brownish-grey: #6c6c6c;
$very-light-pink: #eeeeee;
$brown-grey: #969696;
$black: #2e2d2c;
$orangey-red: #ff322d;
$very-light-pink: #eeeeee;
$very-light-pink-two: #e3e3e3;
$very-light-pink-three: #c0c0c0;
$very-light-pink-four: #dcdcdc;
$greyblue: #6bc0bd;

// Application colors
$application-color: $teal-blue;
$application-color-light: $teal-blue-light;
$application-color-darker: darken($application-color, 50%);
$application-color-transparent: rgba($application-color, 0.4);

// Background colors
$background-color-primary: $teal-blue-light;
$background-color-secondary: #f6ece9;

// Text colors
$text-color-default: $black;
$text-color-primary: $application-color;

@import "manage-stores";

#user-management, .user-management {
    padding-top: 4rem;
    
    @import "user-management/user";

    h1 {
        color: $black;
        text-transform: uppercase;
    }

    .pagination {
        padding: 0;
    }

    .store-item-buttons {
        position: absolute;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        a {
            display: flex;
            align-items: center;
        }

        svg {
            width: auto;
            height: 20px;
            margin-left: 15px;
            cursor: pointer;
        }
    }
}
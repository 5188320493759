.detail-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  .title-image-address {
    max-width: 635px;
    display: flex;
    flex-direction: column;

    .inner-title-row {
      height: 80px;
      padding: 0 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-family: $WorkSans;
        font-size: 2rem;
        margin: 0;
        font-weight: 600;
        color: $application-color;

        @include tablet {
          font-size: 32px;
        }
      }

      @include tablet {
        padding: 0;
        align-items: flex-start;
      }

      .favorite-store-icon {
        width: 1rem;
        vertical-align: top;
        margin-left: 1rem;
        cursor: pointer;
      }

      .number-reviews-rating {
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        flex-direction: column;
      }

      .rating {
        color: $brownish-grey;
        text-align: right;

        .average {
          font-size: 28px;

          line-height: 1;
          font-family: $WorkSans;
          font-weight: 500;

          @include tablet {
            font-size: 38px;
          }
        }

        .max-score {
          font-size: 12px;
          font-family: $WorkSans;

          @include tablet {
            font-size: 1rem;
          }
        }
      }

      .number-of-reviews {
        font-family: $WorkSans;
        color: $brown-grey;

        font-size: 12px;
        white-space: nowrap;

        @include tablet {
          font-size: 16px;
        }
      }
    }
  }
}

.offers {
  background-color: rgba(0, 160, 155, 0.2);
  width: 100vw;
  padding: 1em 14px;

  @include tablet {
    max-width: 305px;
    margin-left: 20px;
    padding: 0;
    background-color: $white;
  }

  .store-action-item {
    margin-right: 1em;
    position: relative;

    .coupon-cont {
      position: absolute;
      width: 300px;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 960px) {
        background-color: #ffffff79;
      }

      .coupon-overlay {
        background-color: $orangey-red;
        font-family: $Montserrat;
        color: $white;
        padding: 6px;
        font-size: 13px;
        text-align: center;
        width: 300px;
        box-sizing: border-box;
        transform: skewY(-15deg);

        p {
          margin: 0;
          transform: skewY(15deg) rotate(-15deg);
        }
      }
    }

    @include tablet {
      margin-right: 0;
    }

    svg {
      background: $white;
    }
  }

  .coupons {
    overflow-x: scroll;
    display: flex;
    justify-content: space-between;

    margin-left: -14px;
    padding: 1em;

    .coupon {
      cursor: pointer;
    }

    @include tablet {
      //flex-direction: column;
      display: block;
      white-space: normal;
      overflow-x: hidden;
      padding: 0 14px;
    }
  }

  .create-account {
    margin-bottom: 34px;
    @include tablet {
      margin-bottom: 0;
    }
  }

  .offer {
    width: 291px;
    height: 124px;
    background-color: $white;
    margin-bottom: 34px;
    display: inline-block;
    margin-right: 10px;

    .offer-content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      background-position: center;
      background-size: cover;
    }

    @include tablet {
      background-color: $light-grey;
      display: block;
    }
  }

  .createAccountStoreDetail {
    height: 124px;
    width: 291px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include tablet {
      height: 278px;
    }
  }

  h2 {
    display: block;
    color: $brownish-grey;
    font-size: 2rem;
    font-family: $Montserrat;
    margin: 34px 0;

    &.no-margin-top {
      margin-top: 0;
    }

    @include tablet {
      display: none;
    }
  }
}

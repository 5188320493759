form {
  .form-group {
    margin: 1em 0;
    display: flex;
    justify-content: space-between;
  }

  button {
    cursor: pointer;
  }

  .errors {
    background-color: $error-color;
    color: $white;
  }

  label {
    position: absolute;
    margin-top: 10px;
    margin-left: 20px;
    color: $brownish-grey;
  }

  input {
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: #6c6c6c 1px solid;

    &:focus {
      outline: none;
    }

    &.required:-webkit-input-placeholder:after {
      content: " *";
      color: red;
    }
  
    &.required:-moz-placeholder:after {
        content: " *";
        color: red;  
    }
  
    &.required:-ms-input-placeholder:after {  
        content: " *";
        color: red;  
    }
  }

  button, p.login {
    background-color: $application-color;
    
    color: $white;
    border: none;
    text-align: left;
    border-radius: 4px;
    height: 50px;
    font-family: $Montserrat;
    font-weight: $medium;
    padding: 0 20px;

    &.loginBtn::after {
      content: "";
    }
  }

  // Auto-complete
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.button {
  background-color: $application-color;

  color: $white;
  border: none;
  text-align: left;
  border-radius: 4px;
  height: 50px;
  font-family: $Montserrat;
  font-weight: $medium;
  padding: 0 20px;

  cursor: pointer;

}



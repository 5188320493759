.content {
  width: 100%;


  @include tablet {
    main {
      padding: 0px 0 15px;
      margin: 0 auto;
      max-width: 960px;
      box-sizing: border-box;
    }
  }

  .text-content {
    padding: 2rem 1rem;
    @include tablet {
      padding: 4rem 0;
    }
  }

}


#contact {
    font-family: $WorkSans;
    position: relative;
    padding-top: 4rem;

    @media (max-width: 960px) {
        padding-top: 2rem;
    }

    * {
        box-sizing: border-box;
    }

    @media (max-width: 759px) {
        form {
            padding: 20px;
        }
    }

    h1 {
        font-weight: $heavy;
    }

    p {
        font-family: $Montserrat;
        font-weight: $light;
        font-size: 14px;

        a {
            color: $brownish-grey;
            text-decoration: underline;
        }
    }

    .required-field {
        text-transform: uppercase;
        font-family: $WorkSans;
        color: $black;

        &::after {
            content: " *";
            color: $orangey-red;
            font-weight: bold;
        }
    }

    input,
    textarea,
    select {
        width: 100%;
        padding: 13px 15px;
        border: 1px solid $brownish-grey;
        border-radius: 5px;
        min-width: 100%;
        max-width: 100%;
        font-family: $Montserrat;
        font-weight: $light;
        font-size: 15px;
        color: $black;
        margin-bottom: 10px;
        margin-top: 10px;

        &::placeholder {
            font-family: $Montserrat;
            font-weight: $light;
            font-size: 15px;
            color: $brown-grey;
        }

        &:focus {
            outline: none;
            border: 1px solid $black;
        }
    }

    .contact__select-container {
        font-family: $Montserrat;
        font-size: 15px;
        font-weight: 300;
    }

    .contact__select {


        &__control {
            color: $black;
            border: 1px solid $brownish-grey;
            border-radius: 5px;
        }

    }

    textarea {
        min-height: 2rem;
    }

    select,
    option {
        background-color: $white;
    }

    button {
        display: flex;
        align-items: center;

        svg {
            margin-left: 20px;

            path {
                fill: $white;
            }
        }
    }

    .notification-container {
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;

        @include tablet {
            top: 0;
            left: 0;
            z-index: 2000;
            background-color: rgba(0, 0, 0, 0.212);
            justify-content: center;
            align-items: center;
        }

        .sent-notification {
            background-color: $white;
            padding: 20px;

            @media (max-width: 759) {
                position: fixed;
            }

            @media (min-width: 960px) {
                border-radius: 4px;

                h2, p {
                    text-align: center;
                }

                button {
                    margin: auto;
                }
            }

            h2 {
                color: $black;
                font-weight: $heavy;
            }

            p {
                font-family: $Montserrat;
                font-weight: $light;
                font-size: 16px;
                color: $brownish-grey;
            }

            button {
                cursor: pointer;
                background-color: $application-color;
                border: none;
                padding: 0 20px;
                height: 50px;
                border-radius: 4px;
                color: $white;
                font-family: $Montserrat;
                font-weight: $medium;
            }

            @media (max-width: 759px) {
                height: 100%;
            }
        }
    }

    .errors {
        background-color: $orangey-red;
    }

    #question {
        overflow-y: hidden;
    }
}

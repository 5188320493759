main {
    .dashboard {
        padding: 50px 15px;

        .dashboard-header {
            margin-top: 5%;

            img {
                width: 33%;
            }

            h2,
            p {
                margin: 0;
            }

            h2 {
                margin-top: 10%;
            }

            p {
                margin-bottom: 10%;
            }
        }

        .dashboard-cards {
            display: flex;
            flex-direction: column;
            margin-top: 10%;
            width: 100%;

            .dashboard-card {
                align-self: center;
                border: 2px solid $application-color;
                box-shadow: 7.1px 7.1px 0 0 $very-light-grey;
                width: 75%;
                padding: 5% 5% 2.5% 5%;
                margin-bottom: 2rem;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .card-header {
                    display: flex;
                    margin: 0;

                    img {
                        width: 64px;
                        height: 54px;
                        max-width: 15vw;
                    }

                    h4 {
                        margin-top: 5%;
                        margin-left: 5%;
                        -ms-hyphens: auto;
                        -moz-hyphens: auto;
                        -webkit-hyphens: auto;
                        hyphens: auto;
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }
        }

        .news-items {
            border-top: 1px solid $light-grey;
            padding-top: 5%;

            @media (min-width: 960px) {
                display: flex;
                justify-content: space-between;
            }

            .news-card-container {
                @media (min-width: 960px) {
                    width: 30%;
                }

                &:hover {
                    cursor: pointer;
                }

                .news-card {
                    box-shadow: rgb(177, 177, 177) 0px 0px 5px;
                    padding: 5px;
                    margin-bottom: 15px;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.05);
                    }

                    .image {
                        width: 100%;
                        height: 150px;
                        overflow: hidden;
                        background-position: center;
                        background-size: cover;
                    }

                    p {
                        padding: 10px;
                    }

                    .item-title {
                        font-family: $WorkSans;
                        font-size: 17px;
                        color: $black;
                        box-sizing: border-box;
                        margin-bottom: 0px;
                        font-weight: $medium;
                        text-align: center;
                    }

                    .date {
                        font-family: $Montserrat;
                        font-weight: $regular;
                        color: $brown-grey;
                        font-size: 10px;
                        text-transform: uppercase;
                        margin-bottom: 0px;
                        text-align: center;
                        padding: 0;
                        padding-bottom: 0.8rem;
                    }
                }
            }
        }
    }
}

@include desktop {
    main {
        .dashboard {
            padding: 0;
            padding-top: 4rem;

            .dashboard-header {
                margin-top: 0;
                display: flex;

                .header-text {
                    order: -1;
                    width: 40%;
                }

                img {
                    height: 370px;
                    width: auto;
                    margin-left: auto;
                }
            }

            .dashboard-cards {
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;
                justify-content: space-between;

                .dashboard-card {
                    width: 30%;
                    height: auto;
                    min-height: 200px;
                    padding: 18px;
                    box-sizing: border-box;

                    .card-header {
                        h4 {
                            hyphens: auto;
                            display: inline;
                            text-overflow: ellipsis;
                            width: 100%;
                        }

                        img {
                            width: 72px;
                            margin-bottom: 30px;
                        }
                    }

                    .card-text {
                        color: $brownish-grey;
                        font-size: 14px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

$rt-namespace: 'Toastify';
$rt-toast-width: 320px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 64px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #00A09B !default;
$rt-color-dark: #121212 !default;
$rt-color-info: #00A09B !default;
$rt-color-success: #023804 !default;
$rt-color-warning: #F9DC5C !default;
$rt-color-error: $orangey-red !default;

$rt-text-color-default: #fff !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: $white !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: "only screen and (max-width : 480px)" !default;
$rt-font-family: $WorkSans !default;
$rt-z-index: 9999 !default;


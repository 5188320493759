#reset-password {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;    
    left: 0;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.247);
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    form {
        width: auto;
        padding: 0;
        width: 100%;
    }

    @media (min-width: 960px) {
        top: 0;
    }

    .errors {
        background-color: $orangey-red;
        color: $white;
    }

    &.hidden {
        display: none;
    }

    * {
        box-sizing: border-box;
    }

    .form-content {
        background-color: $white;
        padding: 20px;
        
        @media (min-width: 960px) {
            border-radius: 10px;
        }

        @media (max-width: 959px) {
            height: 100%;
        }

        .form-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h2 {
                color: $black;
                margin: 0px 0px 20px 0px;
                font-weight: $heavy;
                font-family: $WorkSans;
            }

            svg {
                height: 25px;
                width: auto;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        p {
            margin-bottom: 20px;
        }

        input {
            box-sizing: border-box;
            width: 70%;
            display: block;
            margin-bottom: 20px;

            @media (max-width: 959px) {
                width: 100%;
            }
        }

        button {
            box-sizing: border-box;
            width: 70%;
            display: flex;
            font-size: 16px;
            align-items: center;
            justify-content: space-between;
            
            @media (max-width: 959px) {
                width: 100%;
            }

            svg {
                path {
                    fill: $white;
                }
            }
        }
    }
}
.city-list {
    padding: 0 15px;

    p {
        width: 50%;
        box-sizing: border-box;
        display: inline-block;
        font-family: $Montserrat;

        @media (min-width: 960px) {
            width: 25%;
        }
    }

    .city-list-item {
        cursor: pointer;

        &:hover {
            color: rgb(158, 158, 158);
        }
    }

    .hidden {
        display: none;
    }

    .show-more {
        font-size: 15px;
        width: 100%;
        font-weight: $regular;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &.fold-out {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            height: 7px;
            width: auto;
            margin-left: 5px;
        }
    }
}

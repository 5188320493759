#home>#about {
    @include width-full;

    border-bottom: 1px solid $very-light-pink-two;
    background-color: $background-color-secondary;

    .container {
        @media (min-width: 960px) {
            display: flex;
            padding: 50px 0 50px 0px;
        }
    }

    .about-text {
        padding: 15px;

        .about-title {
            text-transform: uppercase;
            font-size: 2rem;
        }

        @media (min-width: 960px) {
            width: 50%;
            text-align: right;
        }

        .about-title {
            font-family: $WorkSans;
            font-weight: $light;
            color: $application-color;
            font-size: 1.5rem;

            b {
                font-weight: $medium;
            }
        }

        .about-content {
            font-family: $WorkSans;
            font-weight: $light;
            font-size: 14px;
        }
    }

    .selling-points {
        @media (min-width: 960px) {
            width: 50%;
        }

        .selling-points-title {
            text-align: center;
            font-size: 18px;
            font-family: $Montserrat;
            font-weight: $medium;
            margin: 0;
        }

        .selling-points-container-mobile {
            .slick-dots {
                position: relative;
                bottom: 10px;

                li {
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background-color: $very-light-grey;
                    transition: background-color ease 0.7s;

                    button {
                        display: none;
                    }

                    &.slick-active {
                        background-color: $application-color;
                    }
                }
            }

            /* Lots of importants to overwrite Slick styling */
            .selling-point {
                display: flex !important;
                flex-direction: column !important;
                text-align: center !important;
                padding: 15px !important;

                .image-container {
                    @media (max-width: 960px) {
                        display: flex;
                        justify-content: center;
                    }
                }

                img {
                    margin: 10px !important;
                    width: 3rem;
                    height: auto;

                    @media (min-width: 960px) {
                        margin: 0 10px 10px 10px !important;
                    }
                }
            }
        }

        .selling-points-container-mobile,
        .selling-points-container {
            .selling-point-text {
                .point-title {
                    font-family: $WorkSans;
                    font-weight: $medium;
                    color: $text-color-primary;
                    font-size: 21px;
                    margin-bottom: 0;

                    @media (min-width: 960px) {
                        font-size: 19px;
                    }
                }

                .point-content {
                    font-family: $Montserrat;
                    font-weight: $light;
                    color: $brownish-grey;
                    font-size: 16px;
                    margin: 5px 0;

                    @media (min-width: 960px) {
                        font-size: 14px;
                    }
                }
            }
        }

        .selling-points-container {
            display: flex;
            position: relative;

            @media (max-width: 959px) {
                overflow-x: scroll;
                align-items: center;
                width: 100%;
            }

            @media (min-width: 960px) {
                flex-direction: column;
            }

            .selling-point {
                padding: 15px;
                width: 100%;
                flex-shrink: 0;

                @media (max-width: 960px) {
                    text-align: center;
                }

                @media (min-width: 960px) {
                    display: flex;
                }

                img {
                    margin: 10px;

                    @media (min-width: 960px) {
                        margin: 0 10px 10px 10px;
                    }
                }
            }
        }
    }
}

#home>#about {
    border-bottom: none !important;
}
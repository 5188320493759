.edit-news-item {
  p.error {
    color: $red;
  }
  .body {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    .row {
      margin-bottom: 25px;
      display: flex;
      flex-direction: column;
      width: 400px;

      label {
        font-size: 14px;
        color: $black;
        text-transform: uppercase;
        font-family: WorkSans;
        font-weight: bold;
        margin-bottom: 10px;
      }

      input:not(.file-input) {
        border-radius: 4px;
        border: 1px solid $brownish-grey;
        padding: 10px 15px;
      }

      .radio-row {
        display: flex;
        justify-content: space-between;
        width: 200px;

        input {
          align-self: flex-start;
        }
      }

      button {
        border: none;
        cursor: pointer;
        background-color: $application-color;
        border-radius: 4px;
        height: 30px;
        width: 150px;
        color: white;
      }
    }
    .row.content-row {
      width: 100%;

      .news-wrapper {
        .rdw-editor-toolbar,
        .news-editor {
          border: 1px solid $brownish-grey;
          border-radius: 4px;
        }

        .news-editor {
          padding: 5px;
          height: 200px;
        }
      }
    }
  }
}

#city-pictures {
    padding: 0px 0;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    left: 0;

    @media (min-width: 960px) {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-evenly;

        .city-container {
            width: 30%;
            margin-left: 2.5%;
            margin-bottom: 20px;

            .city {
                background-position: center;
                background-size: cover;
                width: 100%;
                transition: transform 0.3s ease;
                position: relative;
                overflow: hidden;

                &:hover {
                    transform: scale(1.05);
                }

                .circle {
                    background-color: $background-color-primary;
                    width: 80%;
                    padding-top: 75%;
                    z-index: 3;
                    top: 40px;
                    position: absolute;
                    margin-left: 10%;
                    border-radius: 100%;
                }

                p {
                    color: $text-color-primary;
                    padding: 10px;
                    margin-bottom: 20px;
                    font-size: 32px;
                    font-family: $WorkSans;
                    font-weight: $regular;
                    z-index: 4;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .city-container {
        position: relative;
        cursor: pointer;
        display: inline-block;

        .city {
            border-radius: 10px;
            background-position: center;
            box-sizing: border-box;
            display: flex;
            align-items: flex-end;
            position: relative;
            margin-right: 15px;
            height: 150px;

            .gradient {
                width: 100%;
                height: 100%;
                position: absolute;
                box-sizing: border-box;
                border-radius: 10px;
                background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
                z-index: 1;
            }
        }
    }
}

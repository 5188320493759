.store-images {
  padding-top: 4rem;

  h1 {
    font-size: 2rem;
  }

  h2 {
    margin-top: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 49px;
  }

  .image-upload-items {
    display: flex;
    flex-wrap: wrap;

    .image-upload-item {
      margin-right: 50px;
    }
  }

  .image-item {
    margin-right: 50px;
    margin-bottom: 50px;
    position: relative;

    .image-delete {
      position: absolute;
      top: 4px;
      right: 4px;
      background: $white;
      width: 31px;
      height: 31px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      > svg {
        height: 21px;
        width: 21px;
      }

    }
  }

  img {
    width: 260px;
    height: 146px;
  }

  .image-number {
    font-family: $WorkSans;
    font-size: 20px;
    font-weight: bold;
    line-height: 3;
    color: $black;
  }

  .image-container {
    position: relative;
  }

  .image-size {
    margin-bottom: 50px;
  }

  .image-select {
    margin-right: 50px;

    .image-upload-button {
      border-radius: 0;

      cursor: pointer;
      width: 260px;
      height: 146px;
      background-color: $medium-grey;
      color: $white;
      border: none;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &:hover {
        background-color: $dark-grey;
      }

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }


  .popup-div {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    top: 0;
    left: 0;

    .close {
      position: absolute;
      width: 20px;
      height: auto;
      top: 10px;
      right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .popup {
      background-color: $white;
      position: absolute;
      padding: 20px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h2 {
        color: $black;
        font-family: $Montserrat;
      }
    }
  }

}

nav {
    position: relative;

    .help-menu {
        position: relative;

        &:hover .help-menu-list {
            display: block;
        }

        .help-title {
            margin: 0 0 0 25px;
            padding: 0;
            font-family: $WorkSans;
            font-weight: $book;
            color: $text-color-primary;
            text-transform: uppercase;
            font-size: 16px;
            display: flex;
            align-items: center;
            user-select: none;

            img {
                margin-left: 8px;
            }

            &::after {
                content: "";
                display: inline-block;
                border: 6px solid transparent;
                border-top-color: currentColor;
                margin-left: 10px;
                margin-top: 3px;
            }
        }

        .help-menu-list {
            display: none;
            height: 100%;
            margin: 0px;
            padding: 0;
            position: absolute;
            left: -205px;
            z-index: 1;

            .menu-arrow {
                height: 30px;
                display: flex;
                flex: 1;
                justify-content: flex-end;
                align-content: flex-end;

                span {
                    width: 0;
                    height: 0;
                    align-self: flex-end;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;

                    border-bottom: 10px solid white;
                    margin-right: 38px;
                }
            }

            div {
                display: block;

                a:first-child {
                    border-top: none;
                }

                a:last-child {
                    border-bottom: none;
                }
            }

            a {
                font-family: $WorkSans;
                font-weight: $book;
                text-decoration: none;
                white-space: nowrap;
                margin: 0;
                line-height: normal;
                font-size: 16px;
                display: block;
                text-align: left;
                color: black;
                background-color: white;
                padding: 13px 100px 13px 13px;
                border-right: 2px solid $very-light-pink-two;
                border-left: 2px solid $very-light-pink-two;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: $very-light-pink-two;
                }

                svg {
                    width: 20px;
                    height: auto;
                    margin-right: 8px;
                }

                &:second-child {
                    border-top: 2px solid $very-light-pink-two;
                }

                &:last-child {
                    border-bottom: 2px solid $very-light-pink-two;
                }
            }
        }
    }
}
#store-owner-details {
    padding-top: 4rem;
    max-width: 100%;

    .errors {
        background-color: $orangey-red;
        color: $white;
    }

    @media (max-width: 960px) {
        padding: 2rem 20px 20px 20px;
    }

    * {
        box-sizing: border-box;
        max-width: 100%;
    }

    h1 {
        font-size: 32px;
        font-weight: $heavy;
    }

    h2 {
        font-size: 2rem;
    }

    h1,
    h2 {
        color: $black;
        text-transform: uppercase;
        font-family: $WorkSans;
    }

    .edit-password {
        display: flex;
        align-content: center;
        margin: 1rem 0;

        h2 {
            margin: 0;
            margin-top: -10px;
        }

        &.open {
            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            width: 20px;
            height: auto;
            margin-left: 1rem;
            display: flex;
            align-content: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    div {
        &.close {
            display: none;
        }
    }
}
#all-cities {
    padding: 0rem 1rem 2rem 1rem;

    @media (min-width: 960px) {
        padding: 0;
        padding-top: 4rem;
    }

    h1 {
        font-weight: $heavy;
        font-family: $WorkSans;
        margin: 0;
        color: $application-color;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: 960px) {
            padding-bottom: 35px;
        }

        @media (max-width: 959px) {
            padding: 35px 0 20px 0;
            margin: 15px 0;
        }
    }

    #city-pictures {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-evenly;

        .city-container {
            margin: 0;
            margin-bottom: 20px;
            width: 100%;

            @media (min-width: 960px) {
                width: 30%;
                margin: 20px 1.6%;
            }

            .city {
                background-position: center;
                background-size: cover;

                width: 100%;
                transition: transform 0.3s ease;
                position: relative;
                overflow: hidden;

                &:hover {
                    transform: scale(1.05);
                }

                .circle {
                    background-color: $background-color-primary;
                    width: 80%;
                    padding-top: 75%;
                    z-index: 3;
                    top: 40px;
                    position: absolute;
                    margin-left: 10%;
                    border-radius: 100%;
                }

                p {
                    color: $text-color-primary;
                    padding: 10px;
                    margin-bottom: 20px;
                    font-size: 32px;
                    font-family: $WorkSans;
                    font-weight: $regular;
                    z-index: 4;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}

#admin-faq {
    padding-top: 4rem;
    
    .popup-cont {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.205);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;

        &.close {
            display: none;
        }

        h2 {
            font-family: $WorkSans;
            text-transform: uppercase;
            color: $black;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 0;
        }

        .popup {
            background-color: $white;
            max-width: 100%;
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-self: center;
            align-items: center;
            border-radius: 8px;
            position: relative;

            .close-icon {
                position: absolute;
                right: 25px;
                top: 25px;
                width: 20px;
                height: auto;

                &:hover {
                    cursor: pointer;
                }
            }

            input {
                margin-bottom: 10px;
            }

            .buttons {
                display: flex;
                justify-content: center;
                font-family: $Montserrat;

                button {
                    margin-bottom: 0;
                }
            }
        }
    }

    .image-container {
        position: fixed;
        border-radius: 8px;
        overflow: hidden;
        top: 0;
        left: 0;
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 2000;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.205);

        &.open {
            display: flex;
        }

        .image-buttons {
            position: relative;
            width: 90%;
            max-width: 960px;

            .image-upload-button {
                width: 100%;
                height: 500px;
                background-position: center;
                background-size: cover;
            }

            .close {
                position: absolute;
                top: 25px;
                right: 25px;
                width: 25px;
                height: auto;
                background-color: $application-color;
                padding: 10px;
                border-radius: 100%;

                cursor: pointer;

                path {
                    fill: $white;
                }
            }
        }
    }

    .open-image {
        margin: 0;
        text-transform: uppercase;
        margin-left: 10px;
        color: $application-color;
        cursor: pointer;
    }

    .main-cat {
        margin-bottom: 30px;

        form {
            position: relative;
        }

        .faq-admin {
            &__image-size {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                background: $white;
                padding: 1em;
                color: $brownish-grey;
                border: 1px solid $black;
                font-family: $Montserrat;
            }
        }


        .new-subcategory {

            .image-select {
                width: 100%;

                .image-upload-button {
                    background-position: center;
                    background-size: cover;
                    margin-bottom: 5px;

                    width: 100%;
                    height: 100px;
                }
            }
        }

        .main-cat-header {
            padding: 20px 20px 20px 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.37);
            border-radius: 6px;

            .header-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $white;
                width: 100%;

                .header-title {
                    display: flex;
                    align-items: center;
                }
            }

            .main-cat-name {
                color: $black;
            }

            .arrow {
                &.open {
                    transform: rotate(180deg);
                }
            }

            .buttons {
                display: flex;
                align-items: center;
            }

            svg {
                width: 20px;
                height: auto;
                margin-left: 10px;
                margin-bottom: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        input,
        textarea {
            font-family: $Montserrat;
            font-weight: $normal;
            padding: 10px 10px 10px 10px;
            width: 100%;
            box-sizing: border-box;
            // margin: 10px 0px 10px 0;

            &:focus {
                outline: none;
            }

            &.disabled {
                border: $very-light-pink-two 1px solid;
            }
        }

        .sub-cat {
            padding: 20px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.37);
            margin-bottom: 30px;
            border-radius: 6px;
            margin-top: 30px;
            position: relative;
            overflow: hidden;

            button {
                margin-bottom: 10px;
                margin-right: 10px;
                color: white;
                background-color: $application-color;
                border: none;
                border-radius: 5px;

                &:focus {
                    outline: none;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .image {
                height: 425px;
                margin-bottom: 10px;
                width: 100%;
                box-sizing: content-box;
            }

            .sub-cat-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                .open-image {
                    margin-bottom: 0;
                }
            }

            .sub-cat-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;

                h2 {
                    margin: 0;
                    color: $black;
                    text-align: left;
                }

                .sub-cat-edit-btn {
                    flex-shrink: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    div {
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    svg {
                        width: 20px;
                        margin-left: 15px;
                        height: auto;
                        fill: $application-color;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            .show-question {
                width: 20px;
                height: auto;
                fill: $application-color;
                margin: auto;

                path {
                    fill: $application-color;
                    color: $application-color;
                }

                &.open {
                    transform: rotate(180deg);
                }

                &:hover {
                    cursor: pointer;
                }

                &.open {
                    transform: rotateZ(180deg);
                }
            }

            .delete-popup-div {
                position: fixed;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: rgba(0, 0, 0, 0.205);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2000;

                .delete-popup {
                    background-color: $white;
                    max-width: 100%;
                    padding: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-self: center;
                    align-items: center;
                    border-radius: 8px;

                    h3 {
                        margin-top: 0px;
                    }

                    button {
                        display: inline-flex;
                        justify-content: center;
                        font-family: $WorkSans;
                        border-radius: 4px;
                        margin: 0;
                        margin-left: 10px;
                        transition: transform 0.2s ease;

                        &:hover {
                            transform: scale(1.1);
                        }

                        svg {
                            width: auto;
                            height: 20px;

                            path {
                                fill: $white;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &.close {
                    display: none;
                }
            }

            .questions {
                box-sizing: border-box;
                margin-bottom: 5px;

                &.close {
                    display: none;
                }

                .new {
                    display: flex;
                    align-items: center;

                    p {
                        font-family: $WorkSans;
                        font-size: 16px;
                        margin: 0;
                        margin-right: 10px;
                        margin-bottom: 6px;
                    }

                    .new-btn {
                        border-radius: 100%;
                        height: 29px;
                        width: 30px;
                        padding-right: 0px;
                        padding-top: 1px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        background-color: $application-color;
                        color: white;
                        font-family: $Montserrat;
                    }
                }

                .q-a {
                    border-bottom: $very-light-pink-two 2px dashed;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    &:last-child {
                        border: none;
                        padding-bottom: 0px;
                    }

                    .q-a-text {
                        .q {
                            color: $black;
                            font-weight: $bold;
                            font-family: $WorkSans;
                            font-size: 18px;

                            &-input {
                                margin-bottom: 5px;
                            }
                        }

                        .a {
                            font-family: $WorkSans;
                            font-weight: $normal;
                            font-size: 16px;
                            margin-bottom: 0;
                            width: 100%;
                            box-sizing: border-box;
                            word-wrap: break-word;
                            overflow: hidden;
                            max-width: 100%;
                            min-width: 100%;
                            height: 100%;
                        }

                        margin-bottom: 20px;
                    }

                    svg {
                        width: auto;
                        height: 20px;
                        display: inline-block;
                        margin-right: 10px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .delete-confirmation {
                        display: inline-block;

                        &.close {
                            display: none;
                        }

                        p,
                        a {
                            display: inline;
                            font-size: 16px;
                            font-family: $WorkSans;
                            font-weight: $normal;
                        }

                        a {
                            &:hover {
                                cursor: pointer;
                                font-weight: $bold;
                            }
                        }
                    }

                    .edit-buttons {
                        display: flex;
                        align-items: center;

                        div:first-child {
                            display: flex;
                            align-items: center;
                        }

                        &.disabled {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

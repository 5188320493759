.manage-cities {
  padding-top: 4rem;
  
  .body {
    p.error {
      color: $red;
    }
    .city-list {
      list-style: none;
      padding: none;
      .city-list-item {
        display: flex;
        width: 300px;
        padding: none;
        td.name {
          width: 100px;
        }
        td.image {
          width: 50px;
          img {
            margin-left: 0.5em;
            width: 1em;
          }
        }
      }
    }
  }
}

#shopper-management {
    @import "shopper";

    h1 {
        color: $black;
        text-transform: uppercase;
    }

    .pagination {
        padding: 0;
    }

    .shopper-total {
        text-transform: uppercase;
        font-family: $WorkSans;
        font-size: 18px;
    }
}
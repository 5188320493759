.user {
    margin-bottom: 20px;
    border-left: $application-color 5px solid;
    border-bottom: $application-color 1px solid;
    border-right: $application-color 1px solid;
    border-top: $application-color 1px solid;
    padding: 10px 0px 10px 20px;
    position: relative;

    h3 {
        color: $black;
        margin: 0;
        margin-bottom: 10px;
        font-family: $Montserrat;
    }

    p {
        font-family: $Montserrat;
        margin: 0;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .link-reviews {
        text-transform: uppercase;
        font-family: $WorkSans;
        display: flex;
        align-items: center;
        margin-top: 15px;

        svg {
            height: 15px;
            width: auto;
            margin-left: 15px;
            margin-top: 5px;
        }
    }

    .reviews {
        p {
            display: inline-block;
        }

        .red {
            color: $red;
        }
    }

    .close, .edit {
        position: absolute;
        width: 20px;
        height: auto;
        top: 10px;
        right: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .edit {
        right: 35px;
    }

    .popup-div {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 100%;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        top: 0;
        left: 0;

        .popup {
            background-color: $white;
            position: absolute;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h2 {
                color: $black;
                font-family: $Montserrat;
            }
        }
    }
}
.news-detail {
    padding: 4rem 1rem;

    @media (max-width: 960px) {
        padding: 2rem 1rem;
    }

    .news-title {
        font-family: $WorkSans;
        font-weight: $regular;
        color: $black;
        font-size: 30px;
        margin: 0 0 18px 0;
    }

    .news-image {
        max-width: 100%;
        // max-height: 358px;

        width: 100%;
    }

    .info {
        padding-bottom: 1rem;

        p {
            display: inline;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 13px;
            font-family: $Montserrat;
            margin-right: 5px;
        }

        .date {
            font-weight: $regular;
        }

        .label {
            font-weight: $medium;
            color: $application-color;
        }
    }

    .content {
        font-weight: $Montserrat;
    }
}

.favorite-stores {
    padding: 2rem 1rem;

    .favorite-stores-content {
        .favorite-stores-city-content {
            display: flex;
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;

                .favorite-stores-item {
                    margin-right: 0;
                }
            }

            .favorite-stores-item {
                position: relative;
                width: 266px;
                align-self: center;

                img.store-image {
                    max-height: 150px;
                    position: relative;
                }

                img.delete {
                    path {
                        fill: $application-color;
                    }

                    position: absolute;
                    width: 30px;
                    right: 10px;
                    top: 10px;
                    cursor: pointer;
                }

                a.favorite-stores-name {
                    margin-top: 5%;
                    margin-bottom: 10%;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.favorite-stores {
    .favorite-stores-header {
        h2 {
            font-family: $WorkSans;
            font-weight: $heavy;
            color: $black;
            text-transform: uppercase;
            font-size: 2rem;
            margin-bottom: 1rem;
            margin-top: 0;
        }

        .subtitle {
            font-family: $WorkSans;
            font-weight: $light;
            color: $brownish-grey;
            font-size: 14px;

            h1 {
                font-family: $WorkSans;
                font-weight: $heavy;
                color: $black;
                font-size: 2rem;
            }

            p,
            a,
            span {
                font-family: $Montserrat;
                font-size: 1rem;
                color: $brownish-grey;
            }
        }
    }

    .favorite-stores-content {
        h3.city-name {
            font-family: $WorkSans;
            font-weight: $medium;
            color: $black;
        }

        .favorite-stores-city-content {
            .favorite-stores-item {
                a {
                    text-decoration: none;
                    color: $application-color;
                    font-family: $WorkSans;
                    font-weight: $medium;
                }
            }
        }
    }
}

@include desktop {
    .favorite-stores {
        padding: 4rem 0;

        .favorite-stores-content {
            .favorite-stores-city-content {
                display: flex;
                flex-wrap: wrap;
                padding-left: 0;
                width: 100%;

                .favorite-stores-item {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 30%;
                    margin-right: 3.3333333%;

                    img {
                        width: 100%;
                    }

                    img.delete {
                        width: 30px;
                    }
                }
            }
        }
    }
}

nav {
    position: relative;

    .user-menu {
        position: relative;

        &:hover .user-menu-list {
            display: block;
        }

        .greeting {
            margin: 0 0 0 10px;
            padding: 0 25px 0 0;
            border-right: 1px solid currentColor;
            font-family: $WorkSans;
            font-weight: $regular;
            color: $text-color-primary;
            text-transform: uppercase;
            font-size: 16px;
        }

        .user-menu-list {
            display: none;
            height: 100%;
            margin: 0px;
            padding: 0;
            position: absolute;
            left: 0px;
            z-index: 1;

            .menu-arrow {
                height: 30px;
                display: flex;
                flex: 1;
                justify-content: flex-start;
                align-content: flex-end;

                span {
                    width: 0;
                    height: 0;
                    align-self: flex-end;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;

                    border-bottom: 10px solid white;
                    margin-left: 25px;
                }
            }

            div {
                display: block;

                a:first-child {
                    border-top: none;
                }

                a:last-child {
                    border-bottom: none;
                }
            }

            a {
                font-family: $WorkSans;
                font-weight: $book;
                text-decoration: none;
                white-space: nowrap;
                margin: 0;
                line-height: normal;
                font-size: 16px;
                display: flex;
                text-align: left;
                color: black;
                background-color: white;
                padding: 13px 50px 13px 13px;
                border-right: 2px solid $very-light-pink-two;
                border-left: 2px solid $very-light-pink-two;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                &:hover {
                    background-color: $very-light-pink-two;
                }

                &:last-child {
                    border-bottom: 2px solid $very-light-pink-two;
                }
            }
        }
    }
}
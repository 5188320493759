.edit-city {
  .body {
    p.error {
      color: $red;
    }
    form {
      display: flex;
      flex-direction: column;
      input,
      textarea {
        width: 400px;
        margin-bottom: 50px;
        padding: 10px 20px;
        border-radius: 5px;
        border: 1px solid $brownish-grey;
      }
      button.image-upload-button {
        margin-bottom: 50px;
        width: 260px;
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
        border: none;
        background-position: center center;
      }
      button.button {
        width: 200px;
      }
    }
  }
}

.sort-dropdown {
    position: relative;

    button {
        background-color: $white;
        padding: 10px 10px 10px 10px;
        width: 100%;
        border: 1px $very-light-pink-three solid;
        border-radius: 5px;
        font-family: $Montserrat;
        font-weight: $light;
        color: $brownish-grey;
        transition: background-color 0.3s;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            margin-left: 50px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: rgb(226, 226, 226);
            cursor: pointer;
        }

        &.open {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    ul {
        position: absolute;
        right: 0;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border: 1px $very-light-pink-three solid;
        border-top: 0;
        width: 100%;
        box-sizing: border-box;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: $white;
        z-index: 1;

        &.close {
            display: none;
        }

        li {
            padding: 10px 10px 10px 10px;
            background-color: $white;
            font-family: $Montserrat;
            font-weight: $regular;
            color: $black;
            border-bottom: 1px $very-light-pink-three solid;
            width: 100%;
            box-sizing: border-box;
            transition: background-color 0.3s;
            user-select: none;

            &:hover {
                background-color: rgb(226, 226, 226);
                cursor: pointer;
            }

            &:last-child {
                border: none;
            }
        }
    }
}
body {
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;

  p {
    margin: 0 0 1em;
  }

  a {
    text-decoration: none;
  }
}

.manage-banners {
  padding-top: 4rem;
  
  .manage-banners-content {
    .images {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      .banner-item {
        width: 260px;
        button {
          width: 260px;
          height: 150px;
          background-size: cover;
          background-repeat: no-repeat;
          border: none;
          background-position: center center;
          cursor: pointer;
        }
      }
    }
  }
}

.dummy-image-form{

  .image-select {
    margin-bottom: 1em;
  }

  .image-upload-button {
    background-position: center;
    width: 635px;
    height: 358px;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    border: 1px solid $black;
    border-radius: 0;
    &:hover {
      transform: none !important;
    }



  }
}

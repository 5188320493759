.my-reviews {
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-content: center;
  padding-top: 4rem;

  @media (max-width: 960px) {
    padding: 2rem 1rem;
  }

  h1 {
    color: $black;
    text-transform: uppercase;
    font-weight: $heavy;
  }

  .subtitle {
    font-family: $Montserrat;
    font-weight: $light;
    font-size: 1rem;

    p {
      font-family: $Montserrat;
      font-weight: $light;
      font-size: 1rem;
    }
  }

  .reviews-header {
    border-bottom: 1px solid $very-light-grey;

    h2 {
      text-transform: uppercase;
      margin-top: 1rem;
      color: $black;
      font-size: 2rem;
      font-family: $Montserrat;
      font-weight: $heavy;
    }

    p.subtitle {
      font-family: $Montserrat;
      font-weight: $light;
      color: $brownish-grey;
      font-size: 1rem;
    }

    .button-container {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 35px;
      margin-bottom: 50px;
      cursor: pointer;

      a {
        text-decoration: none;
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        background-color: $application-color;
        border-radius: 4px;
        color: $white;
        padding: 0 20px;
        font-family: $WorkSans;

        img {
          margin-left: 0.5rem;
          width: 18px;
        }

        p {
          margin: 0.5em 0;
        }
      }
    }
  }

  .reviews-body {
    .review-shop {
      border-top: 1px solid $very-light-grey;
      padding-top: 36px;
      flex-direction: column;

      .my-review-remove {
        cursor: pointer;
      }

      .shop-header {
        display: flex;

        .shop-info {
          h2 {
            color: $application-color;
            margin-bottom: 0;
            font-family: $WorkSans;
            font-weight: $regular;
            font-size: 2rem;
          }

          .subtitle {
            margin-top: 5px;
            color: $brown-grey;
            font-family: $Montserrat;
            font-weight: $light;
            color: $brownish-grey;
            font-size: 14px;
          }
        }

        .my-review-remove {
          margin-left: auto;
          cursor: pointer;
          width: 16px;
          height: 16px;
        }
      }

      .shop-body {
        margin-top: 0 !important;

        .review {
          padding-bottom: 30px;

          .review-header {
            .review-header-row {
              display: flex;
              margin: 0;

              .my-review-title {
                margin: 0;
                display: flex;
                align-items: flex-end;
                color: $application-color;
                font-family: $Montserrat;
                font-weight: $semibold;
                font-size: 16px;
              }

              .my-review-grade {
                font-size: 12px;
                margin: 0 0 0 auto;
                font-family: $WorkSans;
                font-weight: $medium;
                vertical-align: top;

                span.my-review-rating {
                  font-size: 28px;
                  font-family: $WorkSans;
                }
              }
            }

            .my-review-date {
              font-family: $Montserrat;
              font-weight: $light;
              font-size: 12px;
              margin-top: 5px;
            }
          }

          .review-body {
            .my-review-text {
              font-family: $Montserrat;
              font-weight: $light;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.5;
              color: $brown-grey;
            }
          }

          .review-response {
            margin-top: 40px;
            margin-left: 30px;
            padding-left: 10px;
            border-left: 1px solid $very-light-grey;
            display: flex;

            .web-hidden {
              display: none;
            }

            img {
              height: 40px;
              width: 40px;
              color: grey;
            }

            .response {
              .response-title {

                h5,
                p {
                  margin: 0;
                }

                h5 {
                  color: $brownish-grey;
                  font-weight: $Montserrat;
                  font-weight: $medium;
                  font-weight: 500;
                }

                p {
                  color: $brown-grey;
                  line-height: 1.5;
                }

                p.my-review-response-date {
                  font-family: $Montserrat;
                  font-weight: $light;
                  font-size: 12px;
                  font-weight: 300;
                }
              }

              .response-body {
                margin-top: 20px;

                i {
                  color: $brown-grey;
                  font-family: $Montserrat;
                  font-weight: $light;
                  font-size: 12px;
                }
              }
            }
          }
        }

        .review:last-of-type {
          border-bottom: 0;
        }
      }
    }

    .review-shop:last-of-type {
      border-bottom: 0;
    }
  }
}

@include desktop {
  p.my-review-grade {
    font-size: 16px;
  }

  span.my-review-rating {
    font-size: 38px;
    font-family: $WorkSans;
  }

  .my-reviews {
    width: 100%;

    .reviews-header {
      .button-container {
        justify-content: flex-start;
      }
    }

    .review-response {
      .response {
        .response-title {
          .web-hidden {
            display: block;
          }

          .mobile-hidden {
            display: none;
          }
        }

        .response-body {
          margin-top: 20px;
        }
      }
    }
  }
}
#reset-password-page {
    padding: 2rem 1rem;

    @include tablet {
        padding: 2rem 0;
    }

    .errors {
        background-color: $orangey-red;
        color: $white;
    }

    input {
        margin-bottom: 15px;
        box-sizing: border-box;
    }

    h1 {
        font-family: $WorkSans;
        margin-bottom: 30px;
    }

    h2 {
        font-family: $WorkSans;
        color: $black;
        font-size: 15px;
    }
}
$light: 200;
$book: 300;
$normal: 400;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 800;
$black-font: 900;

$rem-base: 16px;

// Calculate rem based on predefined pixel size.
// This allows the program to have the desired size in pixels while still being accessibility-friendly
@function rem($pixels) {
  @return #{$pixels / $rem-base}rem;
}

// Work Sans

@font-face {
  font-family: WorkSans;
  font-weight: $black;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Black.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $black;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-BlackItalic.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $bold;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Bold.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $bold;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-BoldItalic.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $book;
  src: url("../../assets/fonts/Work_Sans/WorkSans-SemiBold.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $book;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-SemiBoldItalic.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $heavy;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Black.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $heavy;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-BlackItalic.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $light;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Light.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $light;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-LightItalic.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $normal;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Regular.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $normal;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Regular.ttf")
    format("opentype");
}

@font-face {
  font-family: WorkSans;
  font-weight: $medium;
  src: url("../../assets/fonts/Work_Sans/WorkSans-Medium.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: $medium;
  font-style: italic;
  src: url("../../assets/fonts/Work_Sans/WorkSans-MediumItalic.ttf");
}

@import "mixins";


// Montserrat
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: black;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Black\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: black;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Black.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $bold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Bold\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $bold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Bold.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $extrabold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ ExtraBold\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $extrabold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ ExtraBold.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $normal;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Italic\.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $light;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Light\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $light;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Light.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $medium;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Medium.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $medium;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Medium\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $normal;
  src: url("../../assets/fonts/Montserrat/Montserrat\ Regular\.otf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $semibold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ SemiBold\ Italic.otf");
}

@font-face {
  font-family: Montserrat;
  font-weight: $semibold;
  src: url("../../assets/fonts/Montserrat/Montserrat\ SemiBold.otf");
}

$base-font-size: 16px;
$base-font-family: Arial, sans-serif;
$WorkSans: WorkSans;
$Montserrat: Montserrat;

body {
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $text-color-default;
}

p {
  font-size: 14px;
}

h1 {
  font-family: $WorkSans;
  color: $brownish-grey;
  margin-top: 0px;
  margin-bottom: 15px;
}

.dashboard-header {
  color: $application-color;
  h2 {
    font-size: 38px;
    font-family: $WorkSans;
    font-weight: bold;
  }
  p.subtitle {
    font-family: $Montserrat;
    font-weight: $light;
    font-size: 38px;
    font-weight: normal;
  }

  .dashboard-info > p{
    font-family: $Montserrat;
    font-weight: $light;
    color: $brownish-grey;

    font-size: rem(18px);
  }
}

.dashboard-card {
  text-decoration: none;
  .card-header {
    h4 {
      font-family: $WorkSans;
      font-weight: $medium;
      color: $application-color;
      font-weight: normal;
      font-size: rem(22px);
    }
  }
  p {
    font-size: 14px;
    font-family: $Montserrat;
    font-weight: $light;
    color: $brownish-grey;
    line-height: 24px;
  }
}

.dashboard-header a {
  color: #6c6c6c;
  font-size: 13px;
}

@include desktop {
  .dashboard-header {
    h2, p.subtitle {
      font-size: 55px;
    }
  }
}

.coupons {
  .coupons-header {
    h1 {
      font-family: $WorkSans;
      font-weight: $heavy;
      text-transform: uppercase;
      color: $black;
    }
    p.subtitle {
      font-family: $Montserrat;
      font-weight: $light;
      font-size: rem(18px);
    }
  }

  .coupon-subtitle {
    color: $brown-grey;
    font-family: Montserrat;
    text-align: center;
    font-size: rem(14px);
    line-height: 22px;
  }

  .coupon-title {
    font-family: $WorkSans;
    font-weight: $medium;
    fill: $application-color;
    font-size: rem(54px);
  }

  .coupons-body {
    h3 {
      font-family: $WorkSans;
      font-weight: $medium;
      font-size: 24px;
      color: $black;
    }

    .coupons-list {
      .coupon-item {
        .coupon-text {
          h2.coupon-title {
            font-family: $WorkSans;
            font-weight: $medium;
            font-size: rem(20px);
            line-height: 29px;
            color: $application-color;
          }
          p {
            font-family: $Montserrat;
            font-weight: $light;
            color: $brownish-grey;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.store-admin.store-images {
  h2 {
    font-size: rem(26px);
  }
}

.favorite-stores-city-content {
  .favorite-stores-item {
    a {
      font-size: rem(20px);
    }
  }
}

.favorite-stores-content {
  h3.city-name {
    font-size: rem(28px);
  }
}

#coupon-management {
  h2 {
    font-size: rem(28px);
  }
}

.home-news {
  .more-news {
    font-size: rem(18px);
  }
}

#contact {
  h1 {
    font-size: rem(44px);
  }
}

.city-overview-store {
  &__actual-rating {
    font-size: rem(29px);
    }
}

.breadcrumb {
  display: none;



  @include desktop {
    display: block;

    position: fixed;
    top: 79px;
    left: 0;

    width: 100vw;
    background: white;
    height: 50px;

    z-index: 100;

    box-shadow: 0px 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .breadcrumb-content {
    max-width: 960px;
    margin: 0 auto;
    height: 100%;

    font-family: $Montserrat;
    color: $black;
    font-size: 12px;
    display: flex;
    align-items: center;

    .breadcrumb-text {

      a {
        color: $black;

      }

      .breadcrumb-arrow-right {
        svg {
          height: 8px;

        }
      }
    }
  }
}
